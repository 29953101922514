<app-card>
  <div card-header>{{ 'information' | translate }}</div>
  <div card-body>
    <p>{{ campaign.info }}</p>
  </div>
</app-card>
<app-filter-active-tags
  [headerActionType]="filterHeaderActionType.edit"
  editDisabled="true"
></app-filter-active-tags>
