<mat-form-field class="collection-name">
  <mat-label>{{ 'name' | translate }}</mat-label>
  <input type="text" matInput [(ngModel)]="collectionName" [required]="true"/>
  <mat-error *ngIf="!collectionName">{{ 'required' | translate }}</mat-error>
</mat-form-field>
<div class="is-public">
  <mat-checkbox color="primary" [(ngModel)]="isPublic">
    {{ 'publicCollection' | translate }}
  </mat-checkbox>
  <app-config-icon
    iconIdentifier="info"
    [tooltip]="'publicCollectionTooltip' | translate"
  ></app-config-icon>
</div>

<app-card class="selected">
  <div card-body>
    <app-grid
      tableId="client-picker-selected"
      [rowData]="selected"
      [columnDefs]="selectedColDefs"
      [gridOptions]="selectedGridOptions"
    ></app-grid>
  </div>
</app-card>

<app-card class="available">
  <div card-body>
    <app-grid
      tableId="client-picker-available"
      [data]="gridData"
      [columnDefs]="clientsColDef"
      [gridOptions]="gridOptions"
      [showRefreshButton]="false"
    ></app-grid>
    <div class="select-all-checkbox-container">
      <mat-checkbox
        [disabled]="selectionProcessing"
        class="select-all-checkbox"
        #selectAllCheckbox
        color="primary"
        [checked]="allSelected()"
        [indeterminate]="someSelected()"
        (click)="togglePageSelection($event)"
        [matTooltip]="selectAllTooltip()"
      ></mat-checkbox>
      <mat-spinner
        *ngIf="selectionProcessing"
        class="overlay-spinner"
        diameter="24"
      ></mat-spinner>
      <div class="selected-entries-count">
        <span>
          {{ 'selected' | translate }}: {{ selectedElements.size}}
        </span>
      </div>
    </div>
  </div>
</app-card>

<div class="actions">
  <button
    mat-stroked-button
    color="primary"
    [disabled]="!canAdd()"
    (click)="addSelection()"
    aria-label="Add Selected Clients"
  >
    <app-config-icon iconIdentifier="keyboard_arrow_left"></app-config-icon>
  </button>
</div>

<div class="dialog-actions">
  <button mat-stroked-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button
    mat-button
    mat-flat-button
    color="primary"
    [disabled]="selectionProcessing || !canSubmit()"
    (click)="onSubmit()"
  >
    {{ 'save' | translate }}
  </button>
</div>
