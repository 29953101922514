<div class="panel-main">
  <mat-expansion-panel expanded hideToggle>
    <mat-expansion-panel-header class="disabled-pointer">
      <mat-panel-title>{{ 'availablePortfolioClientLinksInCollection' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <app-grid
      tableId="campaign-collection-missing-entries-grid"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [data]="gridData"
    >
    </app-grid>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'donePortfolioClientLinksInCollection' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-grid
        tableId="campaign-collection-existing-entries-grid"
        [columnDefs]="existingColumnDefs"
        [gridOptions]="existingGridOptions"
        [data]="existingGridData"
      >
      </app-grid>
    </ng-template>
  </mat-expansion-panel>
</div>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button mat-flat-button color="primary" (click)="dontAdd()">{{ 'dontAdd' | translate }}</button>
  <button mat-flat-button color="primary" (click)="addSelectedEntries()">{{ 'addSelectedEntries' | translate }}
  </button>
</mat-dialog-actions>
