/*! For license information please see index.js.LICENSE.txt */
!function (e, t) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = t(require("blockly/core"));else if ("function" == typeof define && define.amd) define(["blockly/core"], t);else {
    var r = "object" == typeof exports ? t(require("blockly/core")) : t(e.Blockly);
    for (var o in r) ("object" == typeof exports ? exports : e)[o] = r[o];
  }
}(this, e => (() => {
  "use strict";

  var t = {
      370: t => {
        t.exports = e;
      }
    },
    r = {};
  function o(e) {
    var n = r[e];
    if (void 0 !== n) return n.exports;
    var i = r[e] = {
      exports: {}
    };
    return t[e](i, i.exports, o), i.exports;
  }
  o.d = (e, t) => {
    for (var r in t) o.o(t, r) && !o.o(e, r) && Object.defineProperty(e, r, {
      enumerable: !0,
      get: t[r]
    });
  }, o.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t), o.r = e => {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  };
  var n = {};
  o.r(n), o.d(n, {
    FieldDate: () => l,
    isISOFormat: () => d,
    toLocalISOString: () => a
  });
  var i = o(370);
  class l extends i.FieldTextInput {
    constructor(e, t, r) {
      super(e, t, r), this.SERIALIZABLE = !0, this.CURSOR = "text";
    }
    static fromJson(e) {
      const {
        date: t
      } = e;
      return new this(t, void 0, function (e, t) {
        var r = {};
        for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && t.indexOf(o) < 0 && (r[o] = e[o]);
        if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
          var n = 0;
          for (o = Object.getOwnPropertySymbols(e); n < o.length; n++) t.indexOf(o[n]) < 0 && Object.prototype.propertyIsEnumerable.call(e, o[n]) && (r[o[n]] = e[o[n]]);
        }
        return r;
      }(e, ["date"]));
    }
    doClassValidation_(e) {
      if (!e) return null;
      const t = "string" == typeof e ? new Date(e) : null;
      return !t || isNaN(t.getTime()) ? null : d(e) ? e : a(t);
    }
    getText_() {
      const e = this.getValue();
      return e ? function (e) {
        var t;
        const r = new Date(e),
          o = null !== (t = navigator.language) && void 0 !== t ? t : "en-US";
        return new Intl.DateTimeFormat(o, {
          timeZone: "UTC"
        }).format(r);
      }(e) : null;
    }
    render_() {
      super.render_();
    }
    showEditor_(e) {
      var t, r;
      super.showEditor_(e, !0), null === (t = this.htmlInput_) || void 0 === t || t.focus({
        preventScroll: !0
      }), null === (r = this.htmlInput_) || void 0 === r || r.select(), this.showDropdown();
    }
    updateSize_(e) {
      super.updateSize_((null != e ? e : 0) + 20);
    }
    showDropdown() {
      this.htmlInput_ && (i.utils.dom.addClass(this.htmlInput_, "blocklyDateInput"), this.htmlInput_.showPicker());
    }
    widgetCreate_() {
      const e = super.widgetCreate_();
      return e.type = "date", e;
    }
  }
  function d(e) {
    const t = e.match(/\d\d\d\d-\d\d-\d\d/);
    return null !== t && t[0] === t.input;
  }
  function a(e) {
    return e.toLocaleDateString("en-US").replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2").replace(/-(\d)(?!\d)/g, "-0$1");
  }
  return i.utils.userAgent.MAC && i.Css.register("\ninput.blocklyDateInput::-webkit-datetime-edit,\ninput.blocklyDateInput::-webkit-datetime-edit-month-field,\ninput.blocklyDateInput::-webkit-datetime-edit-day-field,\ninput.blocklyDateInput::-webkit-datetime-edit-year-field {\n  padding: 0;\n}\n"), i.fieldRegistry.register("field_date", l), l.prototype.DEFAULT_VALUE = a(new Date()), n;
})());
