import {Component, Input} from "@angular/core";
import {Content, ContentAttachment} from "../../api/core";
import {DocumentTransferService} from "../../services/document-transfer.service";
import {finalize} from "rxjs/operators";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../modal/modal.component";

@Component({
  selector: 'app-content-attachments',
  templateUrl: './content-attachments.component.html'
})
export class ContentAttachmentsComponent {
  @Input()
  content: Content;
  @Input()
  attachments: ContentAttachment[];
  @Input()
  isEditable: boolean;

  constructor(
    readonly documentTransferService: DocumentTransferService,
    public dialogRef: MatDialogRef<ModalComponent>,
  ) {
    this.dialogRef.afterClosed().subscribe(() => this.handleDialogClose());
  }
  fileNamePattern = '^[a-zA-Z0-9_\\-\\.]+$';

  onAdd(e: Event) {
    if (!this.isEditable) return;
    const input = e.target as HTMLInputElement;
    const file = input.files[0];
    this.documentTransferService.uploadAttachment(file.name,file)
      .pipe(finalize(() => (input.value = '')))
      .subscribe((info) => {
        this.attachments.push({
          filename: file.name,
          source: info.documentId
        });
      });
  }

  onDownload(e: Event, a: ContentAttachment) {
    e.preventDefault();
    this.documentTransferService.downloadAttachment(a);
  }

  onDelete(a: ContentAttachment) {
    if (!this.isEditable) return;
    const index = this.attachments.findIndex(ca => a.id == ca.id)
    if (index >=0) {
      this.attachments.splice(index, 1);
    }
  }

  onSaveFilename(a: ContentAttachment) {
    const filenameRegex = new RegExp(this.fileNamePattern);

    if (filenameRegex.test(a.filename)) {
      a.isEditing = false;
      this.dialogRef.componentInstance.toolbarActionData.btnDisabled = false;
      a.filename = a.newFilename;
    }
  }

  onEdit(a: any): void {
    a.newFilename = a.filename;
    a.isEditing = true;
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
  }

  adjustWidth(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.style.width = `${input.value.length + 1}ch`;
  }

  handleDialogClose(): void {
    this.attachments.forEach(a => a.isEditing = false);
  }
}
