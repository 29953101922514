import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {AdvancedFilterField} from 'src/app/models/filter.model';
import {TranslateService} from '@ngx-translate/core';
import {Block, FieldDropdownValidator} from 'blockly';
import {Order} from 'blockly/javascript';
import * as Blockly from 'blockly';

export class BlockFilterOrder extends BlockDefinition {
  constructor(ident: string, readonly fields: AdvancedFilterField[], readonly outputCheckTypes: string[] = ['Boolean']) {
    super(
      'filter_field-order',
      ident,
      fields.some((d) => d.active)
    );
  }

  init(translateService: TranslateService, block: Block, color: string) {
    const validator: FieldDropdownValidator = (newValue) => {
      block.removeInput('field_value', true);
      const input = block.appendDummyInput('field_value');
      const field = this.fields.find((d) => d.ident === newValue);
      input.appendField(new Blockly.FieldDropdown(field.entries), 'value');
      return newValue;
    };
    block
      .appendDummyInput('field_input')
      .appendField(translateService.instant(this.ident) + ':')
      .appendField(
        new Blockly.FieldDropdown(
          this.fields.filter((f) => f.active).map((d) => [d.name, d.ident]),
          validator
        ),
        'field'
      )
      .appendField(new Blockly.FieldDropdown(BlockFilterOrder.comparators), 'operator');

    block.appendDummyInput('field_value').appendField(
      // only show values of first active field in preview
      new Blockly.FieldDropdown(this.fields.find((f) => f.active).entries),
      'value'
    );
    block.setInputsInline(true);
    block.setNextStatement(false, null);
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const field = block.getFieldValue('field');
    const operator = block.getFieldValue('operator');
    const target = block.getFieldValue('value');
    return {
      result: {
        type: `order`,
        name: field,
        operator: BlockFilterOrder.literalComparators[operator],
        valueStr: target,
      },
      order: Order.NONE,
    };
  }
}
