<div class="file" *ngIf="isEditable">
  <label>
    <input type="file" name="add" (change)="onAdd($event)"/>
    <div class="file-content">
      <app-config-icon iconIdentifier="attach_file_add"></app-config-icon>
      <span>{{'attachmentAdd' | translate}}</span>
    </div>
  </label>
</div>
<div class="files">
  <div class="file"
       *ngFor="let a of attachments; let k = index">
    <app-config-icon
      *ngIf="isEditable"
      iconIdentifier="delete_forever"
      [tooltip]="'attachmentDelete' | translate"
      (click)="onDelete(a)"></app-config-icon>
    <a [href]="a.source"
       (click)="onDownload($event, a)"
       tabindex="-1"
       [title]="'attachmentDownload' | translate">
      <app-config-icon iconIdentifier="save_alt"></app-config-icon>
      <span *ngIf="!a.isEditing">{{a.filename}}</span>
    </a>
    <div *ngIf="isEditable">
      <button
        (click)="onEdit(a)"
        *ngIf="!a.isEditing"
        mat-flat-button
        class="edit-save-button"
        color="primary"
      >
        {{'edit' | translate}}
      </button>
      <div *ngIf="a.isEditing" class="filename-edit-container">
        <input
          type="text"
          [(ngModel)]="a.newFilename"
          [pattern]="fileNamePattern"
          class="filename-input form-control"
          (input)="adjustWidth($event)"
          maxlength="120"
        />
        <button
          mat-flat-button
          class="edit-save-button"
          color="primary"
          (click)="onSaveFilename(a)"
          [disabled]="!a.newFilename.match(fileNamePattern)"
        >
          {{'save' | translate}}
        </button>
        <div
          *ngIf="!a.newFilename.match(fileNamePattern)"
          class="error-message"
        >
          {{'filenameValidationError' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
