<app-sub-header [isSmall]="true">
  <app-multi-purpose-button [primaryButtons]="primaryButtons" [dropdownButtons]="dropdownButtons"></app-multi-purpose-button>
  <mat-button-toggle-group
    class="portfolio-list-type"
    name="portfolioListType"
    *ngIf="!!portfolioListType"
    [(ngModel)]="portfolioListType"
    (change)="onPortfolioListTypeChanged($event)">
    <mat-button-toggle [value]="'list'">
      <app-config-icon
        iconIdentifier="list"
        [tooltip]="'showAsList' | translate">
      </app-config-icon>
    </mat-button-toggle>
    <mat-button-toggle [value]="'hierarchy'">
      <app-config-icon
        iconIdentifier="org_tree"
        [tooltip]="'showAsHierarchy' | translate">
      </app-config-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <app-dropdown-selection-bar
    #selectionDropdown
    useDropdownSelect="true"
    placeholder="{{ 'selection' | translate }}"
    *ngIf="showDropdownSelectionBar"
    [actions]="[]"
    [hiddenActions]="collectionButtons"
    (clearSelection)="clearSelection()"
    [forceVisible]="actionType == ActionType.IntermediateAction"
  ></app-dropdown-selection-bar>
  <div class="spacer">&nbsp;</div>
  <div class="href-link font-small"
       (click)="viewSwitched.emit()"
       *ngIf="campaign.status === campaignStatus.LAUNCHED && !hideSwitch"
  >&lt; {{ 'switchToFastTrack' | translate }}</div>
</app-sub-header>
