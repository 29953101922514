import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ApplyColumnStateParams,
  CellClassParams,
  CellClickedEvent,
  CellEditRequestEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent,
  RowSelectedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import {
  Campaign,
  CampaignAction,
  CampaignActionService,
  CampaignPortfolio,
  CampaignPortfolioPaginated,
  CampaignService,
  CampaignStatus,
  Client,
  CodeTableEntry,
  GridFilterOptionsParams,
  ListParams,
  ListParamsWithSelection,
  PortfolioService,
  UserInfo,
  UserInteraction,
  UserService,
} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {DialogHeight, DialogWidth, ModalService} from 'src/app/services/modal.service';
import {ECodeTables, EModalType, EPortfolioActionStatus,} from 'src/app/util/enum';
import {GridComponent, GridResetEvent,} from 'src/app/shared/grid/grid.component';
import {NotificationService} from 'src/app/services/notification.service';
import {ModalData} from 'src/app/models/modal.model';
import {genHeaderCheckboxColumn, GridSelectionUtils,} from 'src/app/util/grid/grid-selection.util';
import {IsCampaignEditablePipe} from 'src/app/shared/shared.pipe';
import {CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {BehaviorSubject, combineLatest, filter, finalize, first, Observable, of, Subscription,} from 'rxjs';
import {
  genCodeTableColumn, genEnumColumn,
  genNumberColumn,
  genPercentageNumberColumn,
  genRiskStateColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genTranslatedTextColumn,
  genUserEnumColumn,
  usernamesValueLabels,
  usernameValueLabel,
} from 'src/app/util/grid/grid-renderer.util';
import {genActionLanguageColumn} from 'src/app/shared/grid/cell-renderers/action-language.renderer';
import {genDialogContentColumn} from 'src/app/shared/grid/cell-renderers/dialog-content.renderer';
import {genIconColumn} from 'src/app/shared/grid/cell-renderers/icon.renderer';
import {genSuitabilityColumn} from 'src/app/shared/grid/cell-renderers/suitability.renderer';
import {
  GridDataProvider,
  GridSelectedItemsProvider,
  GridSelectedItemsProviderByCollection,
} from 'src/app/shared/grid/data-source';
import {genCustomContentColumn} from 'src/app/shared/grid/cell-renderers/custom-content.renderer';
import {genIconButtonColumn} from 'src/app/shared/grid/cell-renderers/icon-button.renderer';
import {ClientDetailsComponent} from 'src/app/shared/client-details/client-details.component';
import {
  CampaignSuitabilitySummaryComponent
} from '../campaign-suitability-summary/campaign-suitability-summary.component';
import {genIconLabelColumn} from '../../../../shared/grid/cell-renderers/icon-label.renderer';
import {GridFilterModel, PerRowObservable} from 'src/app/models/grid.model';
import {EProtectedActions} from '../../../../util/protected-actions';
import {PermissionService} from '../../../../services/permission.service';
import {genViewedColumn, VIEWED_ICONS,} from '../../../../shared/grid/cell-renderers/viewed.renderer';
import {CustomPreviewService} from '../../../../services/custom-preview.service';
import {ActionType, CampaignActionListUtils, ContentAction} from '../campaign-actions-list-utils';
import {LabelBuilder} from "../../../../util/label-builder";
import {genActionStatus, genExecutionAction} from "../../../../shared/grid/cell-renderers/action-status.renderer";
import {CampaignActionDocumentsComponent} from "../campaign-action-documents/campaign-action-documents.component";
import {CampaignActionToolbarComponent} from "../campaign-action-toolbar/campaign-action-toolbar.component";

@Component({
  selector: 'app-campaign-portfolio-list',
  templateUrl: './campaign-portfolio-list.component.html',
})
export class CampaignPortfolioListComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: GridComponent;
  @Output() gridRefreshed: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  selectedAssignees: string[] = [];
  @Input()
  gridSelectionUtils: GridSelectionUtils;
  @Input()
  actionToolbar: CampaignActionToolbarComponent;
  @Input()
  onExpandAll: Observable<void>;
  @Input()
  onCollapseAll: Observable<void>;

  selectionCount: number = 0;

  // intermediate subject that will communicate the grid filter model updates to the table-selection component
  filterModelSubject = new BehaviorSubject<GridFilterModel>({});

  init = false;
  showSpinner = false;

  selectedSort: string;
  campaign: Campaign;
  campaignRelationshipManagers: UserInfo[];
  campaignAdvisors: UserInfo[];
  assignees: UserInfo[];
  initialAssignees: UserInfo[] = [];
  executionUsers: UserInfo[];
  senders: UserInfo[];
  viewedStatus: CodeTableEntry[];
  searchValue: string;
  private isEditableCampaign: boolean;
  private suitabilityRefreshObservable = new PerRowObservable();

  columnDefs: ColDef[] = [];

  detailColumnDefs: ColDef[] = [
    {
      ...genIconLabelColumn({
        field: 'client.personNumber',
        headerName: this.translateService.instant('personNumber'),
        labelCallback: (data: CampaignAction) => this.openClientDetailsModal(data),
        label: (data: any) => data.client.personNumber,
        tooltip: 'showDetails',
      }),
      sortable: true,
      floatingFilter: false,
      hide: true,
    },
    {
      field: 'client.fullName',
      headerName: this.translateService.instant('fullName'),
      floatingFilter: false,
      sortable: false,
      suppressHeaderMenuButton: true,
      resizable: true,
    },
    {
      field: 'client.type.name',
      headerName: this.translateService.instant('type'),
      floatingFilter: false,
      sortable: true,
      suppressHeaderMenuButton: true,
      resizable: true,
      hide: true,
    },
    {
      ...genTranslatedTextColumn(
        'clientRole',
        this.translateService.instant('clientRole'),
        'clientRole.name'
      ),
      floatingFilter: false,
      hide: true,
    },
    {
      ...genTextColumn(
        'assignee.username',
        this.translateService.instant('assignee'),
        null
      ),
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      valueFormatter: (r) => usernameValueLabel(r.data.assignee),
      hide: false,
    },
    {
      ...genTextColumn(
        'sender.username',
        this.translateService.instant('sender'),
        null
      ),
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      valueFormatter: (r) => usernameValueLabel(r.data.sender),
    },
    {
      ...genIconButtonColumn({
        icon: data => VIEWED_ICONS[data.viewed.ident],
        headerName: this.translateService.instant('viewed'),
        tooltip: (params) => {
          const viewed = params.viewed?.ident;
          return viewed === 'no' ? this.translateService.instant('viewedNo') : undefined;
        },
        callback: (data: CampaignAction) => {
          if (data.viewed.ident === 'yes') this.showDocuments(data);
        },
        disabled: (data) => (data.viewed.ident === 'not_sent')
      }),
      sortable: false,
      width: 60,
      maxWidth: 150,
    },
    {
      ...genSuitabilityColumn({
        translateService: this.translateService,
        field: 'suitability',
        stateInfo: (data: CampaignAction) => ({
          state: data.combinedSuitabilityState,
          campaignActionId: data.id,
        }),
        headerName: this.translateService.instant('suitability'),
        callback: (data: any) => this.listUtils.openClientSuitability(data),
      }),
      floatingFilter: false,
      suppressHeaderMenuButton: true,
    },
  ];
  gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    onRowSelected: (event: RowSelectedEvent) => {
      this.gridSelectionUtils.setMultipleSelection(
        event?.data?.actions,
        event?.node?.isSelected()
      );
    },
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.toggleDetails(event.rowIndex),
    rowClassRules: {
      'ag-row-disabled': (params) => params.data && params.data.done,
      'ag-row-warn': (params) => params.data && this.hasClosedEntity(params.data),
    },
    onSortChanged: (event: SortChangedEvent) => {
      this.selectedSort = event.api.getColumn('id')?.getSort();
    },
    getRowId: (params) => params.data.id,
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      // provide the Grid Options to use on the Detail Grid
      detailGridOptions: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        onRowSelected: (event: RowSelectedEvent) => {
          this.gridSelectionUtils.setSelection(
            event?.data?.id,
            event?.data,
            event?.node?.isSelected()
          );
        },
        rowHeight: 36,
        suppressContextMenu: true,
        suppressCellFocus: true,
        readOnlyEdit: true,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        stopEditingWhenCellsLoseFocus: true,
        onCellEditRequest: (event) => this.handleCellEditRequest(event),
        columnDefs: this.detailColumnDefs,
        rowClassRules: {
          'ag-row-disabled': (params) => params.data && params.data.status !== EPortfolioActionStatus.pending,
          'ag-row-warn': (params) => params.data && params.data.client.closed,
        },
      },
      // get the rows for each Detail Grid
      getDetailRowData: (params) => {
        params.successCallback(params.data.actions);
      },
    },
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
  };

  data: GridDataProvider;
  selectAllProvider: GridSelectedItemsProvider;
  selectAllPreselectionProvider: GridSelectedItemsProvider;
  selectAllByCollectionPreselectionProvider: GridSelectedItemsProviderByCollection;
  gridApi: GridApi;

  private languages: CodeTableEntry[];
  private subscriptions: Subscription[] = [];
  private listUtils: CampaignActionListUtils;

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly campaignActionsService: CampaignActionService,
    protected readonly globalService: GlobalService,
    protected readonly modalService: ModalService,
    protected readonly notificationService: NotificationService,
    protected readonly campaignService: CampaignService,
    protected readonly codeTableService: CodeTableService,
    protected readonly isCampaignEditablePipe: IsCampaignEditablePipe,
    protected readonly dataService: DataService,
    protected readonly permissionService: PermissionService,
    protected readonly customPreviewService: CustomPreviewService,
    protected readonly labelBuilder: LabelBuilder,
    protected readonly portfolioService: PortfolioService,
    protected readonly userService: UserService,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.language),
    ]).subscribe(([languages]) => {
      this.languages = languages;
    });
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.updateColumnDefs();
          if (!this.init) {
            this.data = this.fetchData.bind(this);
            this.selectAllProvider = (filter?: string, search?: string) => {
              return this.campaignActionsService.getCampaignActionsSelectionByPortfolio(this.campaign.id, false, filter, search);
            }
            this.selectAllPreselectionProvider =
              this.campaignActionsService.getCampaignActionsSelectionByPortfolio.bind(
                this.campaignActionsService,
                this.campaign.id,
                true
              );
            this.selectAllByCollectionPreselectionProvider =
              this.campaignActionsService.getCampaignActionsSelectionByUserCollectionPortfolio.bind(
                this.campaignActionsService,
                this.campaign.id
              );
            this.listUtils = new CampaignActionListUtils(
              this.zone,
              this.campaign,
              this.notificationService,
              this.modalService,
              this.translateService,
              this.permissionService,
              this.isCampaignEditablePipe,
              this.customPreviewService,
              this.campaignActionsService,
              null,
              this.userService,
            );
            this.actionToolbar.options = {
              gridSelectionUtils: this.gridSelectionUtils,
              selectAllProvider: this.selectAllProvider,
              selectAllPreselectionProvider: this.selectAllPreselectionProvider,
              selectAllByCollectionPreselectionProvider: this.selectAllByCollectionPreselectionProvider,
              selectionCleared: () => this.grid?.gridApi && this.grid.gridApi.deselectAll(),
              refreshTable: (force, redrawRows) => {
                if (!this.grid) return;
                this.grid.refresh();
                this.onGridRefreshed();
              },
              selectionProcessing: loading => this.toggleOverlay(loading),
              searchText: () => this.searchValue
            };
            this.init = true;
            this.columnDefs = this.getDefaultColumnDefs();
          } else {
            this.refreshGrid();
          }
        }),
      this.permissionService.user$.subscribe(() => {
        if (this.gridApi)  {
          // set timeout to let dynamic user update enabled/disabled
          setTimeout(() => {
            this.selectedAssignees = [];
            this.updateAssigneesBasedOnDynamicUsers();
            this.presetAssigneeFilter(this.gridApi);
          }, 0);
        }
      }),
      this.onExpandAll.subscribe(() => {
        if (!this.grid) return;
        this.grid.resetExpandedRows();
        this.expandAll(true)
      }),
      this.onCollapseAll.subscribe(() => {
        if (!this.grid) return;
        this.grid.resetExpandedRows();
        this.expandAll(false)
      }),
    );
    this.updateColumnDefs();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.dataService.clearSelection();
  }

  private fetchData(
    gridParams: ListParams
  ): Observable<CampaignPortfolioPaginated> {
    // avoid fetching data until assignees are loaded
    if (!this.assignees) return of({
      pageItems: [],
      count: 0
    });
    const params = {
      ...gridParams,
      selectedItems: this.selectedSort
        ? this.gridSelectionUtils.getSelectedValues().map((item) => item.id)
        : [],
      selectedSort: this.selectedSort,
    } as ListParamsWithSelection;
    this.toggleOverlay(true);
    return this.campaignActionsService.getCampaignActionsGrouped(
      this.campaign.id,
      params
    ).pipe(finalize(() => this.toggleOverlay(false)));
  }

  onGridRefreshed(): void {
    this.expandAll(false);
    this.gridRefreshed.emit();
  }

  refreshGrid(purgeData: boolean = false): void {
    this.grid?.refresh(purgeData);
  }

  /**
   * Triggered by HTML. Expand or collapse all details
   * @param expand Whether to expand or collapse all details
   */
  expandAll(expand: boolean): void {
    this.showSpinner = true;
    this.gridApi.forEachNode((rowNode) => {
      rowNode.setExpanded(expand);
    });
    setTimeout(() => (this.showSpinner = false), 0);
  }

  getDefaultColumnDefs(): ColDef[] {
    return [
      {
        ...genIconLabelColumn({
          field: 'portfolioNumber',
          headerName: this.translateService.instant('portfolioNumber'),
          labelCallback: (data: CampaignPortfolio) =>
            this.listUtils.openPortfolioDetailsModal(data),
          label: (data: any) => data.portfolioNumber,
          tooltip: 'showDetails',
          icon: (data: CampaignPortfolio) => data.portfolioContainsBuyProducts ? 'warning' : undefined,
          filterParamsInfo: {
            customPath: 'portfolio.number',
            autoCompleteParams: {
              apiMethod: (data: GridFilterOptionsParams) => this.campaignService.getGridFilterOptions(data),
              autoCompleteField: 'number',
              autoCompleteContextId: this.campaign.id,
            },
          },
        }),
        cellRenderer: 'agGroupCellRenderer',
        sortable: true,
      },
      {
        ...genEnumColumn({
          field: 'portfolioContainsBuyProducts',
          headerName: this.translateService.instant('portfolioContainsBuyProducts'),
          values: ['true', 'false'],
          filterParamsInfo: {
            onlyOne: true,
          }
        }),
        hide: true,
      },
      {
        ...genIconColumn({
          field: 'done',
          headerName: this.translateService.instant('done'),
          icon: (data: CampaignPortfolio) => (data.done ? 'done' : undefined),
          tooltip: (data: CampaignPortfolio) =>
            data.done ? this.translateService.instant('done') : undefined,
          customFilter: 'agSetColumnFilter',
        }),
        filterParams: {
          values: [true, false],
          valueFormatter: (params) =>
            this.translateService.instant(`filterDone_${params.value}`),
        },
        suppressColumnsToolPanel: false,
      },
      {
        ...genNumberColumn(
          'portfolioValue',
          this.labelBuilder.labelWithCurrency('portfolioValue'),
          this.globalService,
          null,
          { customPath: 'portfolio.portfolioValue' }
        ),
        hide: true,
      },
      {
        ...genTextColumn(
          'portfolioCurrency.ident',
          this.translateService.instant('referenceCurrency'),
          null,
          { customPath: 'portfolio.referenceCurrency.ident' }
        ),
        hide: true,
      },
      {
        ...genTextColumn(
          'portfolio.bpName',
          this.translateService.instant('bpName'),
          (r) => r.data.portfolioBpName
        ),
        sortable: false,
        floatingFilter: false,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'portfolio.bpNumber',
          headerName: this.translateService.instant('bpNumber'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.campaignService.getGridFilterOptions(data),
            autoCompleteField: 'bpNumber',
            autoCompleteContextId: this.campaign.id,
          },
          valueFormatterFunc: (r) => r.data.portfolioBpNumber,
        }),
      },
      {
        ...genCodeTableColumn({
          field: 'portfolio.type',
          dtoField: 'portfolioType',
          headerName: this.translateService.instant('portfolioType'),
          observable: this.codeTableService.getCodeTable(ECodeTables.portfolioType),
          filterHubs: () => [this.campaign.hub.ident],
        }),
        hide: true,
      },
      {
        ...genCodeTableColumn({
          field: 'portfolio.advisoryType',
          dtoField: 'portfolioAdvisoryType',
          headerName: this.translateService.instant('advisoryType'),
          observable: this.codeTableService.getCodeTable(ECodeTables.advisoryType),
          filterHubs: () => [this.campaign.hub.ident],
        }),
        hide: true,
      },
      {
        ...genCodeTableColumn({
          field: 'portfolio.strategy',
          dtoField: 'portfolioStrategy',
          headerName: this.translateService.instant('strategy'),
          observable: this.codeTableService.getCodeTable(ECodeTables.portfolioStrategy),
          filterHubs: () => [this.campaign.hub.ident],
        }),
        hide: true,
      },
      {
        ...genUserEnumColumn(
          'portfolio.relationshipManager.username',
          this.translateService.instant('relationshipManager'),
          this.fetchCampaignRelationshipManagers.bind(this),
          () => this.campaignRelationshipManagers
        ),
        valueFormatter: (r) =>
          usernameValueLabel(r.data.portfolioRelationshipManager),
      },
      {
        ...genUserEnumColumn(
          'portfolio.advisor.username',
          this.translateService.instant('advisor'),
          this.fetchCampaignAdvisors.bind(this),
          () => this.campaignAdvisors
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.portfolioAdvisor),
        hide: true,
      },
      {
        ...genUserEnumColumn(
          'campaignActions.assignee.username',
          this.translateService.instant('assignee'),
          this.fetchAssignees.bind(this),
          () => this.assignees
        ),
        valueFormatter: (r) =>
          usernamesValueLabels(this.listUtils.distinctUsers(r.data.actions.map((a) => a.assignee))),
        hide: false,
      },
      {
        ...genPercentageNumberColumn(
          'portfolioRisk',
          this.translateService.instant('risk'),
          this.globalService,
          true,
          false,
          {
            customPath: 'portfolio.risk',
          }
        ),
        hide: true,
      },
      {
        ...genPercentageNumberColumn(
          'portfolioRiskSpreadMin',
          this.translateService.instant('riskSpreadMin'),
          this.globalService,
          true,
          false,
          {
            customPath: 'portfolio.riskSpreadMin',
          }
        ),
        hide: true,
      },
      {
        ...genPercentageNumberColumn(
          'portfolioRiskSpreadMax',
          this.translateService.instant('riskSpreadMax'),
          this.globalService,
          true,
          false,
          {
            customPath: 'portfolio.riskSpreadMax',
          }
        ),
        hide: true,
      },
      {
        ...genRiskStateColumn({
          field: 'portfolio.riskState',
          dtoField: 'portfolioRiskState',
          headerName: this.translateService.instant('riskState'),
          observable: this.codeTableService.getCodeTable(ECodeTables.riskState),
          filterHubs: () => [this.campaign.hub.ident],
        }),
        hide: true,
      },
      {
        ...genViewedColumn(
          {
            field: 'viewed',
            headerName: this.translateService.instant('viewed'),
            icon: (data: CampaignPortfolio) => VIEWED_ICONS[data.viewed.ident],
            tooltip: (data: CampaignPortfolio) => data.viewed.ident === 'no' ? this.translateService.instant('viewedNo') : data.viewed.name,
            values: this.fetchViewedStatus.bind(this),
            customPath: 'viewed.id',
          },
          () => this.viewedStatus
        ),
        hide: true,
      },
      genSuitabilityColumn({
        translateService: this.translateService,
        field: 'combinedSuitabilityState',
        stateInfo: (data: CampaignPortfolio) => ({
          state: data.combinedSuitabilityState,
          campaignPortfolioId: data.id,
        }),
        headerName: this.translateService.instant('suitability'),
        callback: (data: any) => this.openSuitabilitySummary(data),
      }),
      {
        ...genIconButtonColumn({
          callback: (data: CampaignPortfolio) => {
            this.refreshSuitability(data);
          },
          finishedLoading: this.suitabilityRefreshObservable,
          tooltip: this.translateService.instant('updateSuitability'),
          icon: 'sync',
          hidden: (data) => {
            return !this.isEditableCampaign
              || data.done
              || !this.permissionService.hasAnyPermission(
                EProtectedActions.refreshSingleSuitabilityCampaign
              )
          },
        }),
        lockVisible: true,
      },
    ];
  }

  private updateColumnDefs(): void {
    this.isEditableCampaign = this.isCampaignEditablePipe.transform(this.campaign.status);
    const updatedClientColumnDefs = [...this.detailColumnDefs];
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(
      EProtectedActions.sortAndFilterCid
    );
    let updatedColumnDefs = this.getDefaultColumnDefs().map((colDef) => {
      if (colDef.field === 'portfolio.bpName') {
        colDef.floatingFilter = isCidFilterAllowed;
        colDef.sortable = isCidFilterAllowed;
      }
      return colDef;
    });
    if (this.campaign.status === CampaignStatus.LAUNCHED) {
      updatedClientColumnDefs.splice(3, 0, {
        ...genCustomContentColumn({
          field: 'hasContentOverride',
          headerName: '',
          hidden: (data: any) =>
            this.campaign.contents?.length === 0 ||
            !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent) ||
            !data.hasCidPermission,
          callback: (data: CampaignAction, action: string) => {
            const contentAction: ContentAction = {
              id: data.id,
              type: ActionType.CampaignAction,
              campaignId: this.campaign.id,
              language: data.language,
              channel: data.channel,
              content: data.content,
            }
            this.listUtils.handleCustomContentAction(contentAction, action, false, this.updateAction.bind(this));
          },
        }),
        floatingFilter: false,
        suppressHeaderMenuButton: true,
      });
    }

    if ([CampaignStatus.LAUNCHED].includes(this.campaign.status)) {
      updatedClientColumnDefs.splice(
        3,
        0,
        ...this.genActionStatusColumnDef(),
        {
          ...genCodeTableColumn({
            field: 'portfolio.preferredLanguage',
            headerName: this.translateService.instant('languagePreferred'),
            filterValues: () => this.languages,
          }),
          sortable: false,
          floatingFilter: false,
        },
        {
          ...genActionLanguageColumn({
            field: 'language.name',
            headerName: this.translateService.instant('languageActual'),
            campaignStatus: this.campaign.status,
          }),
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          singleClickEdit: true,
          editable: (params: any) => this.isDetailLangCellEditable(params),
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: (params) =>
            this.listUtils.getLanguageEditParams(params),
          cellClass: (params: any) =>
            this.isDetailLangCellEditable(params) ? 'editable-cell' : '',
        },
        {
          ...genDialogContentColumn({
            field: 'channel.type.name',
            labelValue: (data: CampaignAction) => data.channel?.type?.name,
            headerName: this.translateService.instant('channel'),
            iconGetter: (data) => (data.content || data.status !== EPortfolioActionStatus.pending) ? 'view' : 'edit_m',
          }),
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          onCellClicked: (event: CellClickedEvent) =>
            this.listUtils.handleChannelCellClick(
              event.data, ActionType.CampaignAction, this.updateAction.bind(this), true
            ),
          cellClass: (params: CellClassParams) => 'editable-cell',
        }
      );
      // replace sender column
      const senderIndex = updatedClientColumnDefs.findIndex(
        (d) => d.field === 'sender.username'
      );
      if (senderIndex > -1) {
        updatedClientColumnDefs.splice(senderIndex, 1, {
          ...genDialogContentColumn({
            field: 'sender.username',
            labelValue: (data: CampaignAction) =>
              usernameValueLabel(data.sender),
            headerName: this.translateService.instant('sender'),
          }),
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          onCellClicked: (event: CellClickedEvent) =>
            this.listUtils.handleSenderCellClick(event.data, ActionType.CampaignAction, () =>
              this.refreshGrid()
            ),
          cellClass: (params: CellClassParams) =>
            this.listUtils.isSenderCellEditable(params.data)
              ? 'editable-cell'
              : '',
        });
      }
    }

    if (this.isCampaignEditablePipe.transform(this.campaign.status)) {
      updatedColumnDefs.splice(
        0,
        0,
        {
          ...genHeaderCheckboxColumn(
              this.gridSelectionUtils,
              true, 0,
              'id',
              true,
              this.translateService.instant('sortBySelected'),
            ),
          lockVisible: true,
        }
      );
      updatedClientColumnDefs.splice(
        0,
        0,
        genHeaderCheckboxColumn(this.gridSelectionUtils, true, 1)
      );
    }

    if (this.campaign.status !== CampaignStatus.DRAFT) {
      updatedColumnDefs = [
        ...updatedColumnDefs,
        {
          ...genIconColumn({
            field: 'test',
            icon: (data) => (this.hasClosedEntity(data) && 'warning') || 'none',
            tooltip: (data) =>
              (this.hasClosedEntity(data) &&
                this.translateService.instant(
                  'closedCampaignPortfolioWarning'
                )) ||
              undefined,
            cellClass: ['danger'],
          }),
          minWidth: 60,
          sortable: false,
        },
      ];
    }
    this.gridOptions.detailCellRendererParams.detailGridOptions.columnDefs =
      updatedClientColumnDefs;
    this.columnDefs = updatedColumnDefs;
  }

  private hasClosedEntity(campaignPortfolio: CampaignPortfolio): boolean {
    return (
      campaignPortfolio.portfolioClosed ||
      campaignPortfolio.actions.some((a) => a.client.closed)
    );
  }

  private openClientDetailsModal(data: any): void {
    const client: Client = data.client;
    const modalData: ModalData = {
      type: EModalType.detailsDialog,
      title: this.translateService.instant('clientDetails'),
      data: client,
      component: ClientDetailsComponent,
    };
    this.modalService.openDefaultDialog(modalData);
  }

  private openSuitabilitySummary(data: any): void {
    const modalData: ModalData = {
      type: EModalType.suitabilitySummary,
      title: EModalType.suitabilityDetails,
      data: {
        state: data.state,
        campaignPortfolioId: data.campaignPortfolioId,
      },
      component: CampaignSuitabilitySummaryComponent,
    };
    this.modalService.openDefaultDialog(
      modalData,
      null,
      false,
      false,
      DialogWidth.DEFAULT,
      DialogHeight.DEFAULT
    );
  }

  private updateLanguage(event: CellEditRequestEvent): void {
    if (event.newValue !== event.oldValue) {
      const newLanguage = this.languages.find(
        (language) => language.name === event.newValue
      );
      this.showSpinner = true;
      this.campaignActionsService
        .updateCampaignActionLanguage(event.data.id, newLanguage.id)
        .pipe(first())
        .subscribe({
          next: (data: CampaignAction) => this.updateAction(data),
          complete: () => (this.showSpinner = false)
        });
      this.userService.recordUserInteraction(
        newLanguage.ident === 'en' ? UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETOEN
          : newLanguage.ident === 'de' ? UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETODE
            : UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETOOTHER
      ).pipe(first()).subscribe();
    }
  }

  private updateAction(data: CampaignAction){
    // since this is a hierarchical grid, we need to refresh the whole grid
    if (data) {
      // update the selection if required
      this.gridSelectionUtils.updateSelection(data.id, data);
      this.refreshGrid();
    }
  }

  private handleCellEditRequest(event: CellEditRequestEvent): void {
    // TODO make edit renderer for key/value pairs
    if (event.colDef.field === 'language.name') {
      this.updateLanguage(event);
    }
  }

  private refreshSuitability(data: CampaignPortfolio) {
    this.campaignActionsService.refreshSuitability(data.id).subscribe(() => {
      this.notificationService.handleSuccess(
        this.translateService.instant('refreshSelectedSuitabilitySuccess')
      );
      this.refreshGrid();
    });
  }

  private fetchAssignees(params: any) {
    if (this.assignees) {
      params.success(this.assignees.map((d) => d.username));
    } else {
      this.campaignActionsService
        .getCampaignActionsAssignees(this.campaign.id)
        .subscribe((data) => {
          this.assignees = data;
          this.initialAssignees = this.assignees;
          this.updateAssigneesBasedOnDynamicUsers();
          params.success(this.assignees.map((d) => d.username));
        });
    }
  }

  private updateAssigneesBasedOnDynamicUsers() {
    const curDynamicUsers: UserInfo[] = this.permissionService.userRoleData.dynamicUsers
      .filter(u => u.enabled).filter(Boolean)
      .map(u => ({id: u.id, username: u.username, fullname: u.fullname}));
    this.assignees = this.initialAssignees.concat(
      curDynamicUsers.filter(u => !this.initialAssignees.find(i => i.username === u.username)));
  }

  private fetchExecutionUsers(params: any) {
    if (this.executionUsers) {
      params.success(this.executionUsers.map((d) => d.username));
    } else {
      this.campaignActionsService
        .getCampaignActionsExecutors(this.campaign.id)
        .subscribe((data) => {
          this.executionUsers = data;
          params.success(this.executionUsers.map((d) => d.username));
        });
    }
  }

  private fetchSenders(params: any) {
    if (this.senders) {
      params.success(this.senders.map((d) => d.username));
    } else {
      this.campaignService
        .getSelectedSenders(this.campaign.id)
        .subscribe((data) => {
          this.senders = data;
          params.success(this.senders.map((d) => d.username));
        });
    }
  }

  private fetchCampaignAdvisors(params: any) {
    this.campaignService
      .getCampaignPortfoliosAdvisors(this.campaign.id)
      .subscribe((data) => {
        this.campaignAdvisors = data;
        params.success(data.map((d) => d.username));
      });
  }

  private fetchCampaignRelationshipManagers(params: any) {
    this.campaignService
      .getCampaignPortfoliosRelationshipManagers(this.campaign.id)
      .subscribe((data) => {
        this.campaignRelationshipManagers = data;
        params.success(data.map((d) => d.username));
      });
  }

  private toggleDetails(index: number): void {
    const isExpanded = this.gridApi.getDisplayedRowAtIndex(index).expanded;
    this.gridApi.getDisplayedRowAtIndex(index).setExpanded(!isExpanded);
  }

  private gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.actionToolbar.gridApi = event.api;
    // force filter-preset
    this.gridApi.showLoadingOverlay();
    this.fetchAssignees({
      success: () => {
        this.presetAssigneeFilter(event.api);
        this.gridApi.hideOverlay();
      },
    });
    this.fetchSenders({
      success: () => {},
    });
    this.applyDefaultSorting(event.api);
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultSorting(event.api);
    this.selectedAssignees = [];
    this.presetAssigneeFilter(event.api);
  }

  private applyDefaultSorting(gridApi: GridApi) {
    // set default sort if nothing is set
    if (gridApi.getColumnState().findIndex((c) => c.sort) === -1) {
      const columnState: ApplyColumnStateParams = {
        state: [
          {
            colId: 'portfolio.bpNumber',
            sort: 'asc',
          },
        ],
      };
      gridApi.applyColumnState(columnState);
    }
  }

  private presetAssigneeFilter(gridApi: GridApi) {
    const currentUsernames = [
      this.permissionService.userRoleData.username,
      ...this.permissionService.userRoleData.dynamicUsers
        .filter((u) => u.enabled)
        .map((u) => u.username),
    ].filter(Boolean);
    this.listUtils.setAssigneeFilter(
      gridApi,
      currentUsernames,
      'campaignActions.assignee.username',
      this.assignees,
      this.selectedAssignees
    )?.then(filter => {
      if (!filter) {
        this.assignees = [];
        this.grid.refresh();
      }
    });
  }

  private isDetailLangCellEditable(params: any): boolean {
    const hasContents = this.campaign.contents?.length > 0;
    return (
      hasContents &&
      this.permissionService.hasAnyPermission(
        EProtectedActions.editActionLanguage
      ) &&
      !params.data.content &&
      this.permissionService.hasPermissionForCampaignOverview(this.campaign) &&
      params.data.status === EPortfolioActionStatus.pending
    );
  }

  private fetchViewedStatus(params: any): void {
    this.codeTableService
      .getCodeTable(ECodeTables.viewedStatus)
      .subscribe((data) => {
        this.viewedStatus = data;
        params.success(data.map((x) => x.id));
      });
  }

  private genActionStatusColumnDef(): ColDef[] {
    return [
      {
        ...genActionStatus(
          this.translateService,
          'status',
          this.translateService.instant('status'),
          this.isEditableCampaign
        ),
        floatingFilter: false,
        suppressHeaderMenuButton: true,
      },
      {
        ...genExecutionAction(
          this.translateService,
          'executionAction',
          this.translateService.instant('executedAction')
        ),
        floatingFilter: false,
      },
      {
        ...genUserEnumColumn(
          'executionUser.username',
          this.translateService.instant('executedBy'),
          this.fetchExecutionUsers.bind(this),
          () => this.executionUsers
        ),
        valueFormatter: (r) =>
          usernameValueLabel(r.data.executionUser),
        floatingFilter: false,
      },
    ];
  }

  getAssigneeFilterValues(): Promise<string[]> {
    if (!this.gridApi) return Promise.resolve([]);
    return this.gridApi.getColumnFilterInstance('campaignActions.assignee.username').then(filter => {
      return Array.from((filter as any).getValueModel().selectedKeys);
    });
  }

  toggleOverlay(loading: boolean) {
    if (loading) {
      this.gridApi.showLoadingOverlay();
    } else {
      this.gridApi.hideOverlay();
    }
  }
  selectAllClicked(selected: boolean) {
    this.actionToolbar?.selectAllClicked(selected);
  }
  showDocuments(campaignAction: CampaignAction) {
    this.campaignActionsService.getClientDocuments(campaignAction.id)
      .subscribe(documents => {
        if (documents.documents && documents.documents.length === 0) {
          this.notificationService.handleInfo(this.translateService.instant("documentsNotFound"))
          return;
        }
        const modalData: ModalData = {
          type: EModalType.clientAccessedDocuments,
          title: EModalType.clientAccessedDocuments,
          data: {
            campaignAction,
            documents: documents.documents,
          },
          cancelBtn: {
            label: this.translateService.instant('close')
          },
          component: CampaignActionDocumentsComponent,
        };
        this.modalService.openDefaultDialog(
          modalData,
          null,
          false,
          null,
          DialogWidth.HALF,
          DialogHeight.HALF
        )
      });
  }
}
