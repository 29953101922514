<div class="suitability-header">
  <div class="status h4">
    <span>{{ 'status' | translate }}:</span>
    <app-config-icon
      [iconIdentifier]="combinedIconAndColorClass.icon"
      [ngClass]="combinedIconAndColorClass.colorClass"
    ></app-config-icon>
  </div>
</div>
<app-grid
  *ngIf="rowData"
  tableId="campaign-suitability-summary-grid"
  [columnDefs]="detailsColumns"
  [gridOptions]="detailsGridOptions"
  [rowData]="rowData">
  >
</app-grid>
