import {Component, HostBinding, OnDestroy} from '@angular/core';
import {Params} from '@angular/router';
import {AgRendererComponent} from 'ag-grid-angular';
import {ColDef, ICellRendererParams} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {GridIconButtonInfo, PerRowObservable,} from 'src/app/models/grid.model';

@Component({
  template: `
    <a
      mat-icon-button
      [routerLink]="routerLinkUrl"
      [queryParams]="queryParams"
      (click)="executeCallback($event)"
      *ngIf="!(hidden | isRendererHidden: data) && !loading"
      [matTooltip]="getTooltip()"
      [disabled]="disabled"
    >
      <app-config-icon
        [iconIdentifier]="getIcon()"
        [outlined]="iconOutlined"
      ></app-config-icon>
    </a>
    <mat-spinner diameter="18" *ngIf="loading"></mat-spinner>
  `,
  styles: ['a { display: flex; justify-content: center; }'],
})
export class IconButtonRenderer implements AgRendererComponent, OnDestroy {
  @HostBinding('class.grid__icon-button') theme = true;
  data: any;
  routerLinkUrl: string;
  queryParams: Params | null;
  icon: string | ((data: any) => string);
  iconOutlined: boolean;
  tooltip: string | ((data: any) => string);
  loading = false;
  hidden: (data: any) => boolean;
  disabled = false;
  private callback: (data: any) => void;
  private finishedLoading: PerRowObservable;
  private finishedLoadingSubscription: Subscription;

  agInit(params: ICellRendererParams): void {
    const cellRendererParams = params.colDef.cellRendererParams || {};

    this.data = params.data;
    const actionParams = cellRendererParams.actionsParams;
    const buttonInfo: GridIconButtonInfo = actionParams.buttonInfo;

    this.icon = buttonInfo?.icon;
    this.iconOutlined = buttonInfo?.iconOutlined ?? false;
    this.tooltip = buttonInfo?.tooltip;
    this.hidden = buttonInfo?.hidden;
    if (buttonInfo?.disabled) {
      this.disabled = buttonInfo.disabled(params.data)
    }
    this.finishedLoading = buttonInfo?.finishedLoading;

    if (buttonInfo?.getRouterUrl) {
      this.routerLinkUrl = buttonInfo.getRouterUrl(this.data);
    }
    if (buttonInfo?.getQueryParams) {
      this.queryParams = buttonInfo.getQueryParams(this.data);
    }
    this.callback = buttonInfo?.callback;
  }

  ngOnDestroy() {
    this.finishedLoadingSubscription?.unsubscribe();
  }

  getTooltip(): string {
    if (typeof this.tooltip === 'function') {
      return this.tooltip(this.data);
    } else {
      return this.tooltip;
    }
  }

  getIcon(): string {
    if (typeof this.icon === 'function') {
      return this.icon(this.data);
    } else {
      return this.icon;
    }
  }

  executeCallback(event: PointerEvent): void {
    event.stopPropagation();
    if (this.finishedLoading) {
      this.loading = true;
      this.finishedLoadingSubscription?.unsubscribe();
      this.finishedLoadingSubscription = this.finishedLoading.subscribe(
        this.data.id,
        () => {
          this.loading = false;
          this.finishedLoadingSubscription.unsubscribe();
          this.finishedLoadingSubscription = null;
        }
      );
    }
    if (this.callback) {
      this.callback(this.data);
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

export function genIconButtonColumn(buttonInfo: GridIconButtonInfo): ColDef {
  const hasHeader = buttonInfo.headerName !== undefined && buttonInfo.headerName !== '';
  return {
    headerName: buttonInfo?.headerName,
    colId: 'icon-button-' + buttonInfo.icon,
    cellRenderer: IconButtonRenderer,
    cellRendererParams: {
      actionsParams: {
        buttonInfo,
      },
    },
    suppressHeaderMenuButton: true,
    suppressColumnsToolPanel: !hasHeader,
    lockVisible: !hasHeader,
    maxWidth: 60,
  };
}
