import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {catchError, finalize, first} from 'rxjs/operators';
import {
  CampaignActionService,
  CampaignIntermediaryActionService,
  CampaignService,
  CodeTableEntry,
  Content,
  ContentAttachment, ContentDefinition, ContentImageHandlingType,
  Gui,
  GuiService,
  StoryService,
} from 'src/app/api/core';
import {ContentData} from 'src/app/models/content.model';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EModalType} from 'src/app/util/enum';
import {FeatureFlagsService} from '../../services/feature-flags.service';
import {ContentFormFieldsComponent} from '../content-form-fields/content-form-fields.component';
import {ContentFormVideoComponent} from '../content-form-video/content-form-video.component';
import {EMPTY, Observable} from "rxjs";
import {ActionType} from "../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {ContentPreviewComponent} from "../content-preview/content-preview.component";
import {NotificationService} from "../../services/notification.service";
import {TranslateService} from "@ngx-translate/core";

/**
 * Component to content form
 */
@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
})
export class ContentFormComponent implements OnInit, ModalSubComponent {
  isEditable: boolean;
  content: Content;
  contentDefinitionObservable: Observable<ContentDefinition>;
  @ViewChild('formFields') formFields: ContentFormFieldsComponent;
  @ViewChild('formVideo') formVideo: ContentFormVideoComponent;
  parentId: number;
  language: CodeTableEntry;
  isCustomContent: boolean;
  enableContentVideo: boolean;
  guiConfig: Gui;
  attachments: ContentAttachment[] = [];
  isEbanking: boolean;
  campaignId: number;
  contentDefinitionId: number;
  contentImageHandling: ContentImageHandlingType;

  // View switch and current field value communication
  tabs = ['edit', 'preview'];
  activeTab = 0;
  @ViewChild(ContentPreviewComponent, { static: false }) private preview: ContentPreviewComponent;

  constructor(
    private storyService: StoryService,
    private campaignService: CampaignService,
    private campaignActionService: CampaignActionService,
    private campaignIntermediaryActionService: CampaignIntermediaryActionService,
    private guiService: GuiService,
    private featureFlagsService: FeatureFlagsService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: ContentData }
  ) {
    this.guiService.getConfig().subscribe((config) => {
      this.guiConfig = config;
    });
    this.parentId = data.data.parentId;
    this.campaignId = data.data.campaignId;
    this.language = data.data.language;
    this.contentImageHandling = data.data.contentImageHandling;
    const isStory = this.dialogRef.componentInstance.type === EModalType.createStoryContent ||
      this.dialogRef.componentInstance.type === EModalType.updateStoryContent;

    this.isEditable = data.data.editable;
    this.content = data.data.content;
    this.contentDefinitionId = data.data.contentDefinitionId;
    this.attachments = [...(this.content?.attachments||[])];
    this.isEbanking = data.data.channel?.type.ident == 'ebanking';

    if (!this.isEditable) {
      setTimeout(() => {
        this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
      });
    } else {
      // initial check if form is valid
      this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !this.mandatoryFieldsSet(this.content);
    }

    this.isCustomContent =
      this.dialogRef.componentInstance.type ===
      EModalType.createCustomContent ||
      this.dialogRef.componentInstance.type === EModalType.updateCustomContent;

    if (isStory) {
      this.contentDefinitionObservable = this.storyService.getContentDefinition(this.parentId, this.contentDefinitionId)
    } else if (this.isCustomContent) {
      this.contentDefinitionObservable = this.campaignService.getContentDefinition(this.campaignId, this.contentDefinitionId)
    } else {
      this.contentDefinitionObservable = this.campaignService.getContentDefinition(this.parentId, this.contentDefinitionId)
    }

    this.featureFlagsService.getFeatureFlags().subscribe((f) => {
      this.enableContentVideo = f.contentVideo && this.isCustomContent;
    });
  }

  ngOnInit(): void {}

  /**
   * Triggered by parent component story-modal.component
   */
  modalAction(modalType: EModalType): void {
    const content: Content = {
      ...this.formFields.withUpdatedContentValues(this.content, this.formFields.contentForm.getRawValue()),
      id: this.content?.id,
      language: this.language,
      video: this.formVideo?.documentId,
      attachments: [...this.attachments],
      contentDefinitionId: this.contentDefinitionId,
      contentDefinitionName: '',
    };
    const catchCb = (err) => {
      const errorMessage = err.error?.error;
      this.notificationService.handleError(
        errorMessage ? this.translateService.instant(errorMessage): err);
      this.dialogRef.componentInstance.resetToolbarActionButtons();
      return EMPTY;
    };
    if (!this.validateContentSize(content)) return;
    switch (modalType) {
      case EModalType.createStoryContent:
        this.storyService
          .createStoryContent(this.parentId, content)
          .pipe(
            first(),
            catchError(catchCb),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => this.dialogRef.close(true),
          });
        break;
      case EModalType.updateStoryContent:
        this.storyService
          .updateStoryContent(this.parentId, content)
          .pipe(
            first(),
            catchError(catchCb),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => this.dialogRef.close(true),
          });
        break;
      case EModalType.createCampaignContent:
        this.campaignService
          .createCampaignContent(this.parentId, content)
          .pipe(
            first(),
            catchError(catchCb),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => this.dialogRef.close(true),
          });
        break;
      case EModalType.updateCampaignContent:
        this.campaignService
          .updateCampaignContent(this.parentId, content)
          .pipe(
            first(),
            catchError(catchCb),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => this.dialogRef.close(true),
          });
        break;
      case EModalType.createCustomContent:
      case EModalType.updateCustomContent:
        this.updateCustomContent(content);
        break;
      default:
        break;
    }
  }

  private updateCustomContent(content: Content) {
    if (!this.validateContentSize(content)) return;
    let serviceCall: Observable<any>;
    if (this.data.data.type === ActionType.CampaignAction) {
      serviceCall = this.campaignActionService
          .editCampaignActionContent(this.parentId, content);
    } else {
      serviceCall = this.campaignIntermediaryActionService.editContent(this.parentId, content)
    }
    const catchCb = (err) => {
      const errorMessage = err.error?.error;
      this.notificationService.handleError(
        errorMessage ? this.translateService.instant(errorMessage): err);
      return EMPTY;
    };
    serviceCall.pipe(
        first(),
        catchError(catchCb),
        finalize(() =>
            this.dialogRef.componentInstance.resetToolbarActionButtons()
        )
    )
    .subscribe({
      next: (data) => this.dialogRef.close(data),
    });
  }

  private validateContentSize(dto: Content): boolean {
    const sizeLong = 8
    const sizeBool = 1
    let size = 0
    size += 2* sizeLong; // id + contentDefinitionId
    size += dto.language.ident.length;
    size += dto.video?.length ?? 0;
    size += dto.contentDefinitionName.length;
    dto.fields.forEach(f => {
      size += sizeLong + sizeBool * 2 // id + isRichText + mandatory
      size += f.type.length
      size += f.value.length
    });
    dto.attachments.forEach(a => {
      size += sizeLong // id
      size += a.source.length
      size += a.filename.length
    });
    dto.channelTypes?.forEach(ct => {
      size += ct.ident.length
    });
    size /= 1024;
    const result = size < this.guiConfig.maxContentSizeKb;
    if (!result) {
      this.notificationService.handleError(this.translateService.instant('contentTooBig'));
      this.dialogRef.componentInstance.resetToolbarActionButtons();
    }
    return result;
  }

  formValidityUpdate(isValid: boolean) {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !isValid;
  }

  tabChanged(evt: MatTabChangeEvent) {
    if (evt.index === 1) {
      this.formVideo.ngAfterViewInit();
    }
  }

  editPreviewChanged(tab: string) {
    let contentUserInput = {
      ...this.formFields.withUpdatedContentValues(this.content, this.formFields.contentForm.getRawValue()),
      video: this.formVideo?.documentId,
    };
    this.preview.updateContentPreview(contentUserInput);
    this.activeTab = this.tabs.indexOf(tab);
  }

  /**
   * Verifies if the content has all mandatory fields set.
   * @param content
   */
  mandatoryFieldsSet(content: Content): boolean {
    if (!content || !content.fields) {
      return false;
    }
    return content.fields.every((field) =>
      !field.mandatory ||
      (field.value !== undefined && field.value !== null && field.value !== '')
    );
  }
}
