<p>
  {{ 'sendToMyselfHint' | translate }}
</p>
<app-grid
  tableId="send-to-myself-grid"
  [columnDefs]="columns"
  [gridOptions]="gridOptions"
  [rowData]="rowData"
>
</app-grid>
