import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {AdvancedFilterField} from 'src/app/models/filter.model';
import {TranslateService} from '@ngx-translate/core';
import {Block} from 'blockly';
import {Order} from 'blockly/javascript';
import * as Blockly from 'blockly';

export class BlockFilterBool extends BlockDefinition {
  constructor(ident: string, readonly fields: AdvancedFilterField[], readonly outputCheckTypes: string[] = ['Boolean']) {
    super(
      'filter_field-bool',
      ident,
      fields.some((d) => d.active)
    );
  }

  init(translateService: TranslateService, block: Block, color: string) {
    block
      .appendDummyInput('field_input')
      .appendField(translateService.instant(this.ident) + ':')
      .appendField(
        new Blockly.FieldDropdown(this.fields.map((d) => [d.name, d.ident])),
        'field'
      );

    block
      .appendDummyInput('field_value')
      .appendField(new Blockly.FieldCheckbox(true), 'value');
    block.setInputsInline(true);
    block.setNextStatement(false, null);
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const field = block.getFieldValue('field');
    const target = block.getFieldValue('value');
    return {
      result: {
        type: 'checkbox',
        name: field,
        valueNum: target === 'TRUE' ? 1 : 0,
      },
      order: Order.NONE,
    };
  }
}
