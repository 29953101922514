import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { OAuthService } from 'angular-oauth2-oidc';
import {filter, finalize, of, share, Subject, Subscription, switchMap, takeUntil, timer} from 'rxjs';
import {
  Campaign,
  CampaignProcessingError,
  CampaignService,
} from 'src/app/api/core';
import { DataService } from 'src/app/services/data.service';
import {
  CampaignProcessingProgress,
  CampaignProcessingProgressSocket,
} from 'src/app/util/campaign-processing-progress-socket';
import { EProtectedActions } from '../../../../util/protected-actions';
import {catchError, retry} from "rxjs/operators";
import {NotificationService} from "../../../../services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {EViewRoutes} from "../../../../util/enum";

/**
 * Component to campaign overview processing
 */
@Component({
  selector: 'app-campaign-overview-processing',
  templateUrl: './campaign-overview-processing.component.html',
})
export class CampaignOverviewProcessingComponent implements OnInit, OnDestroy {
  campaign: Campaign;
  @Input()
  isDeleting: boolean = false;

  progressBarMode: ProgressBarMode = 'indeterminate';
  progressBarValue = 0;
  progressMessage = '';

  math = Math;

  private socket: CampaignProcessingProgressSocket;
  private subscriptions: Subscription[] = [];
  private stopPollingDeletingCampaign = new Subject();

  get protectedActions() {
    return EProtectedActions;
  }

  constructor(
    private readonly dataService: DataService,
    private readonly campaignService: CampaignService,
    private readonly oauthService: OAuthService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly globalService: GlobalService,
  ) {
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          if (!campaign.processing) {
            this.progressBarMode = 'indeterminate';
            this.progressBarValue = 0;
            this.progressMessage = '';
          }
        })
    );
  }

  ngOnInit(): void {
    if (this.isDeleting) {
      this.progressBarMode = 'indeterminate';
      this.progressMessage = 'deleteCampaignProgress';
      this.deleteCampaignAndWait();
      return;
    }
    this.socket = new CampaignProcessingProgressSocket(
      this.campaign.id,
      this.oauthService.getAccessToken()
    );
    this.socket
      .connect()
      .subscribe((progress) => this.handleProgress(progress));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.socket) {
      this.socket.close();
    }
    this.stopPollingDeletingCampaign.next(undefined);
  }

  private deleteCampaignAndWait() {
    // polling to check whether the campaign is already deleted
    const isDeletingCampaign$ = timer(1, 1000).pipe(
      switchMap(() => this.campaignService.getCampaign(this.campaign.id).pipe(
          switchMap(campaign => of(true)), // still deleting
          catchError(err => of(false)) // already deleted
        )
      ),
      retry(),
      share(),
      takeUntil(this.stopPollingDeletingCampaign)
    );
    isDeletingCampaign$.subscribe(result => {
      if (!result) { // not found, campaign deleted
        this.stopPollingDeletingCampaign.next(undefined); // end polling
        this.notificationService.handleSuccess(this.translateService.instant('deleteCampaignSuccess'));
        this.globalService.navigate(EViewRoutes.campaigns);
      }
    });
  }

  retryError(error: CampaignProcessingError) {
    this.campaignService
      .retryProcessingError(error.id)
      .subscribe(() => this.refreshCampaign());
  }

  rollbackError(error: CampaignProcessingError) {
    this.campaignService
      .rollbackProcessing(error.id)
      .subscribe(() => this.refreshCampaign());
  }

  private handleProgress(progress: CampaignProcessingProgress) {
    if (progress.total !== -1) {
      this.progressBarMode = 'determinate';
    } else {
      this.progressBarMode = 'indeterminate';
    }
    this.progressBarValue = (progress.current * 100) / progress.total;
    this.progressMessage = progress.processingMessage;
    if (this.campaign.processing && !progress.processing) {
      this.refreshCampaign();
    }
  }

  private refreshCampaign(): void {
    this.dataService.updateLoading(true);
    this.campaignService
      .getCampaign(this.campaign.id)
      .pipe(finalize(() => this.dataService.updateLoading(false)))
      .subscribe({
        next: (campaign) => this.dataService.updateCampaign(campaign),
      });
  }

  isApplicableForRollback(action: string): boolean {
    const applicationActions = ['FREEZE', 'LAUNCH', 'CLOSE', 'TERMINATE'];
    return applicationActions.includes(action);
  }
}
