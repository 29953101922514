import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent, ModelUpdatedEvent, RowSelectedEvent,} from 'ag-grid-community';
import {Campaign, CampaignIntermediaryService, Intermediary, IntermediaryService, UserInfo,} from 'src/app/api/core';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {ECodeTables, EModalType, EViewRoutes} from 'src/app/util/enum';
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {
  genCodeTableColumn,
  genEnumColumn,
  genTextColumn,
  genUserEnumColumn,
  usernameValueLabel
} from "../../../../util/grid/grid-renderer.util";
import {finalize, first} from 'rxjs';
import {CodeTableService} from "../../../../services/code-table.service";

/**
 * Component to edit portfolios in a campaign.
 */
@Component({
  selector: 'app-add-intermediaries',
  templateUrl: './campaign-intermediary-add.component.html',
})
export class CampaignIntermediaryAddComponent
  implements OnInit, ModalSubComponent {
  entries: Intermediary[] = [];
  relationshipManagers: UserInfo[] = [];

  columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      suppressHeaderMenuButton: true,
      lockPosition: true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      width: 60,
    },
    genLinkColumn({
      field: 'name',
      headerName: this.translateService.instant('name'),
      link: (data: any) => `${EViewRoutes.intermediaryOverview}${data.id}`,
    }),
    genTextColumn('externalKey', this.translateService.instant('key')),
    genEnumColumn({
      field: 'type',
      values: ['EWA', 'EAM'],
      headerName: this.translateService.instant('type'),
    }),
    genCodeTableColumn({
      field: 'hub',
      headerName: this.translateService.instant('hub'),
      observable: this.codeTableService.getCodeTable(ECodeTables.hub),
      filterHubs: () => [this.campaign.hub.ident],
    }),
    {
      ...genUserEnumColumn(
        'leadRelationshipManager.username',
        this.translateService.instant('relationshipManager'),
        this.fetchRelationshipManagers.bind(this),
        () => this.relationshipManagers
      ),
      valueFormatter: (r) => usernameValueLabel(r.data.leadRelationshipManager),
    },
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    rowMultiSelectWithClick: true,
    paginationAutoPageSize: true,
    rowSelection: 'multiple',
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
    onRowSelected: (event: RowSelectedEvent) => this.rowSelected(event),
    onModelUpdated: (event: ModelUpdatedEvent) => this.modelUpdated(event),
    getRowId: (data) => data.data.id,
  };
  gridApi: GridApi;
  gridData: GridDataProvider;

  private campaign: Campaign;

  constructor(
    private readonly intermediaryService: IntermediaryService,
    private readonly campaignIntermediaryService: CampaignIntermediaryService,
    private readonly translateService: TranslateService,
    private readonly codeTableService: CodeTableService,
    private dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { entity: Campaign } }
  ) {
    this.campaign = data.data.entity;
    this.gridData =
      this.campaignIntermediaryService.getUnrelatedForCampaign.bind(
        this.campaignIntermediaryService,
        this.campaign.id
      );
  }

  ngOnInit(): void {
  }

  modalAction(modalType: EModalType): void {
    switch (modalType) {
      case EModalType.addCampaignIntermediaries:
        this.campaignIntermediaryService
          .addUnrelatedToCampaign(
            this.campaign.id,
            this.entries.map((t) => t.ident)
          )
          .pipe(
            first(),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => {
              this.dialogRef.close({success: true});
            },
          });
        break;
      default:
        break;
    }
  }

  private isSelected(id: number): boolean {
    return this.entries.findIndex((it) => it.id === id) !== -1;
  }

  /**
   * Grid ready function. Gets triggered when grid is finished loading.
   * @param event GridReadyEvent
   */
  private gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    // set default sort if nothing is set
  }

  private rowSelected(event: RowSelectedEvent): void {
    const selectedIdx = this.entries.findIndex(
      (it) => it.id === event.data.id
    );
    if (event.node.isSelected()) {
      if (selectedIdx === -1) {
        this.entries.push({...event.data});
      }
    } else {
      if (selectedIdx > -1) {
        this.entries.splice(selectedIdx, 1);
      }
    }
    this.dialogRef.componentInstance.submitBtn.label =
      this.translateService.instant('addSelectedIntermediaries', {
        count: event.api.getSelectedRows().length,
      });
  }

  private modelUpdated(event: ModelUpdatedEvent): void {
    event.api.forEachNode((node) =>
      this.isSelected(node.data?.id)
        ? node.setSelected(true)
        : node.setSelected(false)
    );
  }

  private fetchRelationshipManagers(params: any) {
    this.intermediaryService
      .getLeadRelationshipManagers()
      .subscribe((data) => {
        this.relationshipManagers = data;
        params.success(data.map((d) => d.username));
      });
  }

}
