<app-campaign-action-toolbar
  #campaign_action_toolbar
  [actionType]="ActionType.CampaignAction"
  [portfolioListType]="portfolioListType"
  [pageButtons]="dropdownButtons"
  (portfolioTypeChanged)="onPortfolioTypeChanged($event)"
  (viewSwitched)="viewSwitched.emit()"
></app-campaign-action-toolbar>

<app-campaign-portfolio-list
  *ngIf="portfolioListType === 'hierarchy'"
  [gridSelectionUtils]="gridSelectionUtils"
  [selectedAssignees]="selectedAssignees"
  (gridRefreshed)="gridRefreshed.emit()"
  [actionToolbar]="campaign_action_toolbar"
  [onExpandAll]="expandAll$"
  [onCollapseAll]="collapseAll$"
></app-campaign-portfolio-list>

<app-campaign-action-list
  *ngIf="portfolioListType === 'list'"
  [gridSelectionUtils]="gridSelectionUtils"
  [selectedAssignees]="selectedAssignees"
  (gridRefreshed)="gridRefreshed.emit()"
  [actionToolbar]="campaign_action_toolbar"
></app-campaign-action-list>
