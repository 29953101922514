<form [formGroup]="actionForm" *ngIf="availables" class="form-fields">
  <div class="field">
    <div class="field-label">{{ 'language' | translate }}:</div>
    <mat-radio-group formControlName="language">
      @for (lang of availables.languages; track lang) {
        <mat-radio-button color="primary" [value]="lang" [ngClass]="$index % 2 === 1 ? 'odd': '' ">{{ lang.name }}</mat-radio-button>
      }
    </mat-radio-group>
  </div>
  <div class="field">
    <div class="field-label">{{ 'channel' | translate }}:</div>
    <mat-radio-group formControlName="channel">
      @for (channel of channels; track channel) {
        <mat-radio-button color="primary" [value]="channel" [ngClass]="$index % 2 === 1 ? 'odd': '' ">
          {{ channel.type.name }} {{ channel.address ? ' - ' + channel.address : '' }}
        </mat-radio-button>
      }
    </mat-radio-group>
  </div>
  <div class="field-double">
    <div>{{ 'sender' | translate }}:</div>
    <mat-form-field class="mat-form-field-standard">
      <mat-select formControlName="sender">
        <mat-option *ngFor="let sender of availables.senders" [value]="sender">
          {{ sender.fullname }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
