<form [formGroup]="storyForm" class="modal-form" style="padding:4px;">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{ 'storyName' | translate }}</mat-label>
      <input matInput formControlName="name" type="text" />
      <mat-error *ngIf="!storyForm.controls.name.valid">{{
        'inputTooLong'
          | translate: { maxLength: formValidators.textFieldMaxLength }
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="row row-double">
    <mat-form-field>
      <mat-label>{{ 'useCase' | translate }}</mat-label>
      <mat-select formControlName="useCase" (selectionChange)="updateCodeTables()">
        <mat-option *ngFor="let useCase of useCases" [value]="useCase">
          {{ useCase.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'publicationType' | translate }}</mat-label>
      <mat-select formControlName="publicationType" (selectionChange)="updateCodeTables()">
        <mat-option *ngFor="let publicationType of filteredPublicationTypes" [value]="publicationType">
          {{ publicationType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="canEditHub">
      <mat-label>{{ 'hub' | translate }}</mat-label>
      <mat-select formControlName="hub" (selectionChange)="onHubChanged()">
        <mat-option *ngFor="let hub of hubs" [value]="hub">
          {{ hub.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'author' | translate }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="authorControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        (optionSelected)="onAuthorSelect($event)"
      >
        <mat-option
          *ngFor="let option of filteredAuthors$ | async"
          [value]="option"
        >
          {{ option.fullname }} ({{ option.username }})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-slide-toggle color="primary" formControlName="hot" class="hot-toggle">{{
      'hotStory' | translate
    }}</mat-slide-toggle>
  </div>
  <div class="row row-double">
    <mat-form-field>
      <mat-label>{{ 'validFrom' | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="validFromDatePicker"
        [min]="minDate"
        [max]="storyForm.controls.validTo.value"
        (click)="validFromDatePicker.open()"
        formControlName="validFrom"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="validFromDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #validFromDatePicker></mat-datepicker>
      <mat-error *ngIf="!storyForm.controls.validFrom.valid">{{
        'dateNotValid' | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'validTo' | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="validToDatePicker"
        [min]="storyForm.controls.validFrom.value"
        [max]="maxDate"
        (click)="validToDatePicker.open()"
        formControlName="validTo"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="validToDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #validToDatePicker></mat-datepicker>
      <mat-error *ngIf="!storyForm.controls.validTo.valid">{{
        'dateNotValid' | translate
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="row row-single tags" *ngIf="story">
    <mat-form-field>
      <mat-label>Tags</mat-label>
      <mat-chip-grid #chipList aria-label="Tags selection">
        <mat-chip-row *ngFor="let tag of tags.value" (removed)="removeTag(tag)">
          {{ tag.name }}
          <button matChipRemove>
            <app-config-icon iconIdentifier="cancel"></app-config-icon>
          </button>
        </mat-chip-row>
        <input
          placeholder="{{ 'newTag...' | translate }}"
          #tagInput
          [formControl]="tagControl"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matAutocomplete]="autoTag"
          (matChipInputTokenEnd)="getOrCreateTag($event)"
        />
        <mat-autocomplete
          #autoTag="matAutocomplete"
          (optionSelected)="onTagSelect($event)"
        >
          <mat-option
            *ngFor="let option of filteredTags$ | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-chip-grid>
    </mat-form-field>
    <span class="body">{{ 'pressEnterNewTag' | translate }}</span>
  </div>
  <div class="row row-double" id="split-panel">
      <mat-form-field>
        <mat-label>{{ 'storyInfo' | translate }}</mat-label>
        <textarea matInput formControlName="info" type="text" [readonly]="!canEditInfo" maxlength="10000"></textarea>
      </mat-form-field>
    <app-external-links
      [externalLinks]="externalLinks"
      (externalLinksChanged)="handleExternalLinksChanged($event)"
    ></app-external-links>
  </div>
</form>
