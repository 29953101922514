<div class="story-details-file">
  <div class="preview-container">
    <div class="preview aspect aspect-16-9">
      <app-config-icon
        iconIdentifier="landscape"
        class="image-placeholder"
        *ngIf="!coverImageUrl"
      ></app-config-icon>
      <div
        class="image"
        [ngStyle]="{
                'background-image': 'url(' + coverImageUrl + ')'
              }"
        *ngIf="coverImageUrl"
      ></div>
      <ng-container *ngIf="canEdit">
        <button
          mat-icon-button
          (click)="imageInput.click()"
          type="button"
          color="primary"
          class="mat-btn edit-image"
        >
          <app-config-icon
            [tooltip]="'uploadImage' | translate"
            iconIdentifier="upload_file"
          ></app-config-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="coverImageUrl && canDelete"
          (click)="deleteImage()"
          type="button"
          color="warn"
          class="mat-btn delete-image"
        >
          <app-config-icon
            [tooltip]="'removeImage' | translate"
            iconIdentifier="delete"
          ></app-config-icon>
        </button>
      </ng-container>
    </div>
  </div>
  <div class="file">
    <input
      #imageInput
      type="file"
      accept="image/*"
      (change)="onChangeFileUpload('image', $event)"
    />
  </div>
</div>
