import {Component, Inject} from '@angular/core';
import {Campaign} from "../../../../api/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {EFilterHeaderActionType} from "../../../../util/enum";

@Component({
  selector: 'app-campaign-compact-targeted-clients',
  templateUrl: './campaign-compact-targeted-clients.component.html',
})
export class CampaignCompactTargetedClientsComponent {

  campaign: Campaign;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.campaign = data.data.campaign;
  }

  get filterHeaderActionType() {
    return EFilterHeaderActionType;
  }
}
