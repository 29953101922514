<div class="campaign-intermediary-list-wrapper">
  <div class="table-selection-container">
    <app-table-selection-bar
      *ngIf="columnDefs && data"
      [gridApi]="data && gridApi"
      [campaign]="campaign"
      isHierarchyList="true"
      [filterModelSubject]="filterModelSubject"
      (selectionProcessing)="toggleOverlay($event)"
      (searchChanged)="(searchValue = $event)"
      (selectAllClicked)="selectAllClicked($event)"
      (selectionCountChanged)="(selectionCount = $event)"
      searchPlaceholder="searchIntermediaryList"
    ></app-table-selection-bar>
  </div>
  <!--
  This grid has gridFilterEnabled=false because we are rendering a grid-filter component in the table-selection-bar component.
  Therefore, the search must be handled by the table-selection-bar component and delegated to the grid component.
 -->
  <app-grid
    *ngIf="init"
    #grid
    [gridFilterEnabled]="false"
    [searchValue]="searchValue"
    tableId="campaign-intermediary-list"
    [data]="data"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    (gridFiltersReset)="gridFilterReset($event)"
    (gridFiltersChanged)="filterModelSubject.next($event.api.getFilterModel())"
    [labelRefs]="{
        singular: 'recipientSelected',
        plural: 'recipientsSelected'
      }"
    [selectionCount]="selectionCount"
    (selectAllClicked)="selectAllClicked($event)"
    card-body
  ></app-grid>
</div>
