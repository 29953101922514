<div class="compact-preview" [innerHTML]="htmlStr" *ngIf="htmlStr"></div>
<div class="compact-column">
  <app-card class="compact-products" [showHeader]="false">
    <div card-body class="compact-products-grid">
      <app-tabs-panel
        [tabs]="tabs"
        [activeTab]="activeTab"
        [defaultTab]="defaultTab"
        (tabChanged)="onTabChanged($event)"
      ></app-tabs-panel>
      <app-grid
        tableId="compact-products"
        *ngIf="products.length"
        [columnDefs]="productsColumns"
        [gridOptions]="productsGridOptions"
        [rowData]="products"
        [gridFilterEnabled]="false"
        [pagination]="false"
        [hideFooter]="true"
      ></app-grid>
      <div class="compact-products-empty" *ngIf="products.length == 0">
        <span>{{'productsNone' | translate}}</span>
      </div>
    </div>
  </app-card>
  <app-card class="additional-information" [showHeader]="false">
    <div class="additional-information-wrapper" card-body>
      <app-tabs-panel
        [tabs]="additionalTabs"
        [activeTab]="additionalActiveTab"
        [defaultTab]="additionalDefaultTab"
        (tabChanged)="onAdditionalTabChange($event)"
      ></app-tabs-panel>
      <div class="detail-items" *ngIf="additionalActiveTab==additionalTabs[0].text">
        <app-overview-detail-item
          labelRef="useCase"
          [value]="campaign.useCase?.name"
          icon="business_center"
        ></app-overview-detail-item>
        <app-overview-detail-item
          *ngIf="permissionService.userRoleData.hubs.length > 1"
          labelRef="hub"
          [value]="campaign.hub.name + (campaign.decentralized ? ' [' + ('decentralized' | translate) + ']' : '')"
          icon="hub"
        ></app-overview-detail-item>
        <app-overview-detail-item
          labelRef="publicationType"
          [value]="campaign.publicationType?.name"
          *ngIf="campaign.publicationType"
          icon="business_center"
        ></app-overview-detail-item>
        <app-overview-detail-item
          labelRef="valid"
          [value]="validPeriod"
          icon="date_range"
        ></app-overview-detail-item>
      </div>
      <div class="compact-links" *ngIf="additionalActiveTab === additionalTabs[1].text" [style]="linksStyle">
        <div class="link-item h3" *ngFor="let link of links">
          <app-config-icon [iconIdentifier]="link.icon"></app-config-icon>
          <a href="{{ link.url }}" target="_blank" tabindex="-1">
            {{ link.display }}
          </a>
        </div>
      </div>
      <div class="compact-links" *ngIf="additionalActiveTab === additionalTabs[2].text" [style]="linksStyle">
        <div class="link-item h3" *ngFor="let link of attachments">
          <app-config-icon [iconIdentifier]="link.icon"></app-config-icon>
          <a href="{{ link.url }}" target="_blank" tabindex="-1">
            {{ link.display }}
          </a>
        </div>
      </div>
      <div class="compact-space">
      </div>
    </div>
  </app-card>

</div>
<div class="compact-footer">
  <button mat-raised-button (click)="onBack()">
    {{ 'backFastTrack' | translate }}
  </button>
  <button mat-raised-button color="primary"  (click)="onNext()">
    {{ 'next' | translate }}
  </button>
</div>
