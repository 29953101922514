<app-campaign-overview-processing [isDeleting]="isDeleting"
                                  *ngIf="!campaign || campaign.processing || isDeleting"
></app-campaign-overview-processing>
<div *ngIf="campaign && !campaign.processing && !isDeleting" class="page-content-container">
  <app-tabs-panel
    [tabs]="tabs"
    [activeTab]="activeTab"
    [defaultTab]="defaultTab"
    (tabChanged)="onTabChanged($event)"
  >
    <app-campaign-overview-toolbar tab-action="" (onDeleting)="onDeleting()"></app-campaign-overview-toolbar>
  </app-tabs-panel>

  <div *ngIf="activeTab === tabs[detailsTab].text" class="overview-container-parent">
    <div class="overview-details-container">
      <div class="details-column">
        <app-campaign-overview-details></app-campaign-overview-details>
        <div class="stats-filters-wrapper">
          <app-statistics class="horizontal"></app-statistics>
          <app-filter-active-tags
            [headerActionType]="filterHeaderActionType.edit"
            [editDisabled]="!canEditFilters"
          ></app-filter-active-tags>
        </div>
      </div>
      <div class="charts-column" *ngIf="campaign.status === campaignStatus.DRAFT">
        <app-risk-return-chart-card class="scatter-card"></app-risk-return-chart-card>
        <app-additional-charts-card
          class="additional-charts-card"
          (selectedChartChanged)="selectedChartChanged($event)"
          [campaign]="campaign"
        ></app-additional-charts-card>
      </div>
      <div class="charts-column" *ngIf="campaign.status !== campaignStatus.DRAFT">
        <app-campaign-suitability-kpi-chart-card class="kpi-card"></app-campaign-suitability-kpi-chart-card>
        <app-campaign-actions-kpi-chart-card class="kpi-card"
                                             [campaign]="campaign"></app-campaign-actions-kpi-chart-card>
      </div>
    </div>
  </div>

  <div *ngIf="activeTab === tabs[contentTab].text" class="content-container">
    <app-content-card
      class="campaign-contents"
      [allowAddOrEditContent]="allowEditContent()"
      (addOrEditContent)="handleAddOrEditContent($event)"
      (deleteContent)="handleDeleteContent($event)"
      (restoreContent)="handleRestoreContent()"
      (toggleAutoSync)="handleToggleAutoSyncContent()"
    ></app-content-card>
    <app-products-card
      class="product-list"
      [allowEditProducts]="allowEditProducts(storyProductType.BUY)"
      [productType]="storyProductType.BUY"
      (editProducts)="handleEditProducts(storyProductType.BUY)"
      (deleteProduct)="handleDeleteProduct($event, storyProductType.BUY)"
      (restoreProducts)="handleRestoreProducts(storyProductType.BUY)"
      (toggleAutoSync)="handleToggleAutoSyncProducts(storyProductType.BUY)"
    ></app-products-card>
    <app-products-card
      class="product-list"
      [allowEditProducts]="allowEditProducts(storyProductType.SELL)"
      [productType]="storyProductType.SELL"
      (editProducts)="handleEditProducts(storyProductType.SELL)"
      (deleteProduct)="handleDeleteProduct($event, storyProductType.SELL)"
      (restoreProducts)="handleRestoreProducts(storyProductType.SELL)"
      (toggleAutoSync)="handleToggleAutoSyncProducts(storyProductType.SELL)"
    ></app-products-card>
  </div>

  <div class="tab-content-container" *ngIf="activeTab == tabs[suitabilityTab].text">
    <app-campaign-suitability-review></app-campaign-suitability-review>
  </div>
  <div
    *ngIf="activeTab === tabs[portfoliosTab].text"
    class="tab-content-container tab-content-grid-only"
  >
    <ng-container *ngIf="campaign.status === campaignStatus.DRAFT">
      <app-portfolio-list [data]="data"></app-portfolio-list>
    </ng-container>
    <ng-container *ngIf="campaign.status === campaignStatus.FROZEN || campaign.status === campaignStatus.CLOSED || campaign.status === campaignStatus.TERMINATED">
      <app-campaign-send
        [portfolioListType]="portfolioListType"
        (gridRefreshed)="onPortfolioListRefresh()"
      >
      </app-campaign-send>
    </ng-container>
    <ng-container *ngIf="campaign.status === campaignStatus.LAUNCHED">
      <app-campaign-send
        *ngIf="!useCompactSend"
        [portfolioListType]="portfolioListType"
        (gridRefreshed)="onPortfolioListRefresh()"
        (viewSwitched)="switchToCompactOverview()"
      >
      </app-campaign-send>
      <app-campaign-compact-send
        *ngIf="useCompactSend"
        [campaign]="campaign"
        (viewSwitched)="switchToCompactOverview()"
        (onBack)="activeTab = tabs[0].text">
      </app-campaign-compact-send>
    </ng-container>
  </div>

  <ng-container *ngIf="campaign.status === campaignStatus.DRAFT">
    <div *ngIf="activeTab === tabs[usersTab].text" class="tab-content-container">
      <app-overview-allowed-users
        [campaign]="campaign"
        (toggleAutoSync)="handleToggleAutoSyncAllowedUsers()"
      >
      </app-overview-allowed-users>
    </div>
    <div *ngIf="activeTab === tabs[intermediariesTab].text" class="tab-content-container tab-content-grid-only">
      <app-intermediary-list
        [data]="intermediaryData"
        [currentFilter]="currentFilter"
        [showHub]="false"
      ></app-intermediary-list>
    </div>
  </ng-container>
  <ng-container *ngIf="campaign.status !== campaignStatus.DRAFT">
    <div *ngIf="activeTab === tabs[intermediariesTab].text" class="tab-content-container tab-content-grid-only tab-intermediaries">
      <app-campaign-action-toolbar
        #campaign_intermediary_toolbar
        [hideSwitch]="true"
        [actionType]="ActionType.IntermediateAction"
        [pageButtons]="intermediaryButtons"
      ></app-campaign-action-toolbar>
      <app-campaign-intermediary-list
        [actionToolbar]="campaign_intermediary_toolbar"
        [onCollapseAll]="collapseAll$"
        [onExpandAll]="expandAll$"
      >
      </app-campaign-intermediary-list>
    </div>

    <div *ngIf="activeTab === tabs[usersTab].text" class="tab-content-container">
      <app-overview-allowed-users
        [campaign]="campaign"
        (toggleAutoSync)="handleToggleAutoSyncAllowedUsers()"
      >
      </app-overview-allowed-users>
    </div>
  </ng-container>
</div>
