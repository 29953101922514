import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, filter, finalize, Observable, Subscription, switchAll} from 'rxjs';
import {
  Campaign,
  CampaignService,
  CampaignStatus,
  IntermediaryService,
  ListParams,
  PortfolioService,
  UserInteraction,
  UserService,
} from 'src/app/api/core';
import {ModalData} from 'src/app/models/modal.model';
import {DataService} from 'src/app/services/data.service';
import {FilterUtilsService} from 'src/app/services/filter-utils.service';
import {DialogHeight, DialogWidth, ModalService} from 'src/app/services/modal.service';
import {NotificationService} from 'src/app/services/notification.service';
import {EModalType,} from 'src/app/util/enum';
import {CampaignPortfolioListComponent} from "../campaign-portfolio-list/campaign-portfolio-list.component";
import {CampaignActionListComponent} from "../campaign-action-list/campaign-action-list.component";
import {GridSelectionUtils} from "../../../../util/grid/grid-selection.util";
import {FilterConfig} from "../../../../models/filter.model";
import {PermissionService} from "../../../../services/permission.service";
import {GridDataProvider} from "../../../../shared/grid/data-source";
import {convertFilterConfigToFilterBody} from "../../../../services/filter.config-body";
import {EProtectedActions} from "../../../../util/protected-actions";
import {CampaignPortfolioAddComponent} from "../campaign-portfolio-add/campaign-portfolio-add.component";
import {CampaignSendToMyselfComponent} from "../campaign-send-to-myself/campaign-send-to-myself.component";
import {PortfolioListType} from "../campaign-overview.component";
import {PageBarButton} from "../../../../services/page.bar.service";
import {PipeLogicService} from "../../../../services/pipe.logic.service";
import {ActionType} from "../campaign-actions-list-utils";
import {first} from "rxjs/operators";

/**
 * Component for campaign overview.
 */
@Component({
  selector: 'app-campaign-send',
  templateUrl: './campaign-send.component.html',
})
export class CampaignSendComponent implements OnInit, OnDestroy {
  @ViewChild(CampaignPortfolioListComponent)
  portfolioList: CampaignPortfolioListComponent;

  @ViewChild(CampaignActionListComponent)
  actionList: CampaignActionListComponent;

  @Input() portfolioListType: PortfolioListType = 'list';

  @Output() gridRefreshed = new EventEmitter<void>();
  @Output() viewSwitched = new EventEmitter<void>();

  gridSelectionUtils = new GridSelectionUtils(
    () => true,
    (data) =>
      data.extractedInfo
        ? data
        : {
          id: data.id,
          status: data.status,
          advisor: data.portfolio?.advisor,
          hasCidPermission: data.hasCidPermission,
          campaignPortfolioId: data.campaignPortfolioId,
          action: data,
          portfolio: data.portfolio,
          extractedInfo: true,
        }
  );
  selectedAssignees: string[] = [];

  campaign: Campaign;
  currentFilter: FilterConfig;
  collapseAll$: Observable<void>;
  expandAll$: Observable<void>;
  private subscriptions: Subscription[] = [];
  private collapseAllSource = new BehaviorSubject<void>(undefined);
  private expandAllSource = new BehaviorSubject<void>(undefined);

  dropdownButtons: PageBarButton[] = [
    {
      icon: 'refresh',
      text: 'refresh',
      click: () => {this.refreshPortfolioList();},
      disabled: () => !this.pipeLogicService.isCampaignEditablePipe(this.campaign.status),
      show: () => !['CLOSED', 'TERMINATED'].includes(this.campaign.status)
    },
    {
      icon: 'add',
      text: 'addPortfolios',
      click: () => {this.addPortfolios();},
      show: () =>
        (
          this.campaign.decentralized
          && this.permissionService.hasAnyPermission(EProtectedActions.decentralizedCampaignEdit)
          && this.pipeLogicService.isCampaignEditablePipe(this.campaign.status)
        )
        || (
          !this.campaign.decentralized
          && this.permissionService.hasAnyPermission(EProtectedActions.addPortfolioCampaign)
          && this.pipeLogicService.isCampaignEditablePipe(this.campaign.status)
        )
    },
    {
      icon: 'mail',
      text: 'sendToMyself',
      click: () => {this.onSendToMyself();},
      show: () => this.permissionService.hasAnyPermission(EProtectedActions.executeActionCampaign) &&
        this.campaign.status === CampaignStatus.LAUNCHED,
    },
    {
      icon: 'expand',
      text: 'expandAll',
      click: () => this.expandAllSource.next(),
      show: () => this.portfolioListType == 'hierarchy'
    },
    {
      icon: 'compress',
      text: 'collapseAll',
      click: () => this.collapseAllSource.next(),
      show: () => this.portfolioListType == 'hierarchy'
    }
  ]

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly modalService: ModalService,
    protected readonly campaignService: CampaignService,
    protected readonly translateService: TranslateService,
    protected readonly dataService: DataService,
    protected readonly filterService: FilterUtilsService,
    protected readonly notificationService: NotificationService,
    protected readonly portfolioService: PortfolioService,
    protected readonly permissionService: PermissionService,
    protected readonly intermediaryService: IntermediaryService,
    protected readonly userService: UserService,
    protected readonly pipeLogicService: PipeLogicService,
  ) {
    this.expandAll$ = this.expandAllSource.asObservable();
    this.collapseAll$ = this.collapseAllSource.asObservable();
  }

  /**
   * Data Provider for Product grid
   */
  data: GridDataProvider = (listParams: ListParams) =>
    this.portfolioService.getPortfoliosPreview({
      hub: this.campaign.hub.ident,
      filter: convertFilterConfigToFilterBody(
        this.currentFilter || {},
        this.permissionService.userRoleData),
      listParams,
    });

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.refreshList(); // Refresh the list after campaign update, we are forcing it here because of the async nature of the grid
        })
    );
    this.subscriptions.push(
      this.dataService.filter$.subscribe(
        (f) => (this.currentFilter = f)
      )
    );
    this.updateFlatView();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private updateFlatView() {
    const campaignOverviewTab = localStorage.getItem('campaignOverviewTab');
    if (!campaignOverviewTab) return;
    switch (campaignOverviewTab) {
      case 'flat':
        this.portfolioListType = 'list';
        break;
      case 'tree':
        this.portfolioListType = 'hierarchy';
        break;
    }
    localStorage.removeItem('campaignOverviewTab');
  }

  get campaignStatus() {
    return CampaignStatus;
  }

  get modalType() {
    return EModalType;
  }

  get protectedActions() {
    return EProtectedActions;
  }

  addPortfolios(): void {
    const modalTitleRef = 'addPortfoliosList';

    const modalData: ModalData = {
      type: EModalType.addCampaignPortfolios,
      title: modalTitleRef,
      data: {entity: this.campaign},
      submitBtn: {
        label: this.translateService.instant('addPortfolios'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: CampaignPortfolioAddComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData, undefined, true, true
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.refreshCampaign();
      }
    });
  }

  refreshPortfolioList(): void {
    if (this.portfolioListType === 'list') {
      this.actionList.refreshGrid(true);
    } else {
      this.portfolioList.refreshGrid(true);
    }
  }

  private refreshCampaign(): void {
    this.dataService.updateLoading(true);
    this.campaignService
      .getCampaign(this.campaign.id)
      .pipe(finalize(() => this.dataService.updateLoading(false)))
      .subscribe((campaign) => this.dataService.updateCampaign(campaign));
  }

  // refreshes the portfolio list and action list, if they are present
  private refreshList(): void {
    this.portfolioList?.refreshGrid();
    this.actionList?.refreshGrid();
  }

  switchToFlatView(): void {
    if (this.portfolioListType === 'list') {
      return
    }
    this.togglePortfolioListType();
  }

  switchToHierarchyView(): void {
    if (this.portfolioListType === 'hierarchy') {
      return
    }
    this.togglePortfolioListType();
  }

  togglePortfolioListType(): void {
    const switchType = () => {
      if (this.portfolioListType === 'list') {
        this.portfolioListType = 'hierarchy';
      } else {
        this.portfolioListType = 'list';
      }
    };
    (this.portfolioList ?? this.actionList).getAssigneeFilterValues()
      .then((assignees) => {
        this.selectedAssignees = assignees;
        switchType();
      });
  }

  onPortfolioTypeChanged(listType: PortfolioListType) {
    if (this.portfolioListType == listType) return;
    this.portfolioListType = listType;
    this.userService.recordUserInteraction(
      listType === 'list' ? UserInteraction.CLICKEDFLATVIEWBUTTON : UserInteraction.CLICKEDHIERARCHYVIEWBUTTON
    ).pipe(first()).subscribe();
    setTimeout(() => {
      (this.portfolioList ?? this.actionList).getAssigneeFilterValues()
        .then((assignees) => {
          this.selectedAssignees = assignees;
        });
    });
  }

  onSendToMyself() {
    const modalData: ModalData = {
      type: EModalType.sendToMyself,
      title: EModalType.sendToMyself,
      data: {
        campaign: this.campaign,
      },
      submitBtn: {
        label: this.translateService.instant('send')
      },
      cancelBtn: {
        label: this.translateService.instant('cancel')
      },
      component: CampaignSendToMyselfComponent,
    };
    this.modalService.openDefaultDialog(
      modalData, undefined, true, true, DialogWidth.HALF, DialogHeight.HALF
    );
  }

  protected readonly switchAll = switchAll;
  protected readonly ActionType = ActionType;
}
