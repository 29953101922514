<app-card class="campaign-processing">
  <div card-header>
    {{ 'campaignProcessing' | translate }}
  </div>
  <div card-body>
    <div class="flex-container">
      <span class="h1">{{ 'campaign' | translate }} '{{ campaign?.name }}'</span
      ><span class="h2" *ngIf="progressMessage">
        - {{ progressMessage | translate }}</span
      >
      <span class="h2" *ngIf="campaign?.error">
        - {{ 'processingFailed' | translate }}</span
      >
    </div>
    <div>
      <span>{{
        (progressBarMode === 'determinate' &&
          math.round(progressBarValue) + '%') ||
          ' '
      }}</span>
      <mat-progress-bar
        [mode]="progressBarMode"
        [value]="progressBarValue"
      ></mat-progress-bar>
    </div>
    <div *ngIf="campaign?.error && protectedActions.processCampaign | protectedAction" class="buttons">
      <button
        mat-flat-button
        color="primary"
        (click)="retryError(campaign.error)"
      >
        {{ 'tryAgain' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="rollbackError(campaign.error)"
        *ngIf="isApplicableForRollback(campaign.error.action)"
      >
        {{ 'rollback' | translate }}
      </button>
    </div>
  </div>
</app-card>
