import {Component, Input} from "@angular/core";
import {finalize, Observable, switchMap} from "rxjs";
import {DataService} from "../../../services/data.service";
import {DocumentTransferService} from "../../../services/document-transfer.service";
import {loadImageFile, resizeImage} from "../../../services/froala-editor.service";


@Component({
  selector: 'app-story-details-image',
  templateUrl: './story-details-image.component.html'
})
export class StoryDetailsImageComponent {
  @Input()
  coverImageUrl: string;
  @Input()
  canEdit: boolean;
  @Input()
  canDelete: boolean;
  @Input()
  onUpload: (documentId: string) => Observable<string>;
  @Input()
  onDelete: () => Observable<any>;

  constructor(
    protected readonly dataService: DataService,
    protected readonly documentTransferService: DocumentTransferService,
  ) {
  }

  async onChangeFileUpload(image: string, event: any) {
    if (event.target.files && event.target.files.length > 0) {
      this.dataService.updateLoading(true);
      const file = event.target.files[0];
      const src = await loadImageFile(file);
      const imageData = await resizeImage(src);
      this.documentTransferService
        .uploadDocument('image', imageData.dataURL)
        .pipe(
          finalize(() => this.dataService.updateLoading(false)),
          switchMap(this.onUpload)
        )
        .subscribe((imageUrl) => {
          this.coverImageUrl = imageUrl;
          event.target.value = '';
        });
    }
  }

  deleteImage() {
    this.dataService.updateLoading(true);
    this.onDelete()
      .pipe(finalize(() => this.dataService.updateLoading(false)))
      .subscribe(() => {
          this.coverImageUrl = null;
      });
  }
}
