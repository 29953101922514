import {Component, Inject, OnInit} from '@angular/core';
import {ModalSubComponent} from "../../../../models/modal.model";
import {ECodeTables, EModalType} from "../../../../util/enum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Campaign, CampaignService, ContentWithChannels} from "../../../../api/core";
import {ColDef, GridApi, GridOptions, GridReadyEvent, RowSelectedEvent} from "ag-grid-community";
import {genCodeTableColumn, genEnumColumn} from "../../../../util/grid/grid-renderer.util";
import {TranslateService} from "@ngx-translate/core";
import {CodeTableService} from "../../../../services/code-table.service";
import {first} from "rxjs";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {NotificationService} from "../../../../services/notification.service";

/**
 * Component to campaign overview processing
 */
@Component({
  selector: 'app-campaign-send-to-myself',
  templateUrl: './campaign-send-to-myself.component.html',
})
export class CampaignSendToMyselfComponent implements OnInit, ModalSubComponent {
  campaign: Campaign;

  rowData: ContentWithChannels[];
  gridApi: GridApi;

  columns: ColDef[] = [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      lockVisible: true,
      lockPosition: true,
      sortable: false,
    },
    genCodeTableColumn({
      field: 'language',
      headerName: this.translateService.instant('language'),
      observable: this.codeTableService.getCodeTable(ECodeTables.language),
    }),
    genEnumColumn({
      field: 'contentDefinitionName',
      headerName: this.translateService.instant('definition'),
      values: (params) => params.success(
        [...new Set(this.rowData.map(f => f.contentDefinitionName))]
      )
    }),
    {
      ...genCodeTableColumn({
        field: 'channelTypes',
        headerName: this.translateService.instant('channelType'),
        observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
        filterHubs: () => [this.campaign.hub.ident],
      }),
      valueFormatter: (params) => params.data.channelTypes.map(f => f.name).join(', '),
      sortable: false,
    }
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    floatingFiltersHeight: 0,
    suppressContextMenu: true,
    suppressCellFocus: true,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    paginationAutoPageSize: true,
    pagination: true,
    onGridReady: (event) => this.gridReady(event),
    onRowSelected: (event: RowSelectedEvent) => this.rowSelected(event),
  }

  constructor(
    private translateService: TranslateService,
    private codeTableService: CodeTableService,
    private campaignService: CampaignService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.campaign = data.data.campaign;
  }

  ngOnInit(): void {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
    this.campaignService.getCampaignChannelContents(this.campaign.id)
      .pipe(first())
      .subscribe(contents => {
        this.rowData = contents;
      });
  }

  modalAction(modalType: EModalType) {
    if (modalType === EModalType.sendToMyself) {
      this.campaignService
        .sendCampaignChannelContents(this.campaign.id, this.gridApi.getSelectedRows().map(c => c.id))
        .pipe(first())
        .subscribe({
          next: () => {
            this.dialogRef.close(true);
            this.notificationService.handleSuccess(this.translateService.instant('sendToMyselfSuccess'));
          },
          error: (error) => this.notificationService.handleError(error),
        });
    }
  }

  private gridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  private rowSelected(event: RowSelectedEvent) {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = event.api.getSelectedRows().length === 0;
  }
}
