import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridOptions, RowClickedEvent} from 'ag-grid-community';
import {filter, Observable, Subscription} from 'rxjs';
import {
  Campaign,
  CampaignStatus,
  CodeTableEntry,
  Content,
  ContentDefinition,
  ContentService,
  Story
} from 'src/app/api/core';
import {ContentData} from 'src/app/models/content.model';
import {genIconButtonColumn} from 'src/app/shared/grid/cell-renderers/icon-button.renderer';
import {CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {ECodeTables, EModalType, EReferenceType} from 'src/app/util/enum';
import {ModalData} from '../../models/modal.model';
import {ContentPreviewComponent, ContentPreviewParams,} from '../content-preview/content-preview.component';
import {ModalService} from '../../services/modal.service';
import {FeatureFlags, FeatureFlagsService,} from '../../services/feature-flags.service';
import {DocumentTransferService} from '../../services/document-transfer.service';
import {PermissionService} from '../../services/permission.service';
import {EProtectedActions} from '../../util/protected-actions';
import {ActionType} from "../../campaign/views/campaign-overview/campaign-actions-list-utils";

/**
 * Component for content card
 */
@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
})
export class ContentCardComponent implements OnInit, OnDestroy {
  @Input() set allowAddOrEditContent(value: boolean) {
    const origValue = this._allowAddOrEditContent;
    this._allowAddOrEditContent = value;
    if (origValue !== value) {
      this.updateColumnDefs();
    }
  }
  get allowAddOrEditContent(): boolean {
    return this._allowAddOrEditContent;
  }
  private _allowAddOrEditContent = false;
  @Output() addOrEditContent: EventEmitter<ContentData> =
    new EventEmitter<ContentData>();
  @Output() deleteContent: EventEmitter<number> = new EventEmitter<number>();
  @Output() restoreContent: EventEmitter<void> = new EventEmitter<void>();

  @Output() toggleAutoSync: EventEmitter<void> = new EventEmitter<void>();

  loading$: Observable<boolean>;

  allLanguages: CodeTableEntry[];
  allContentDefinitions: ContentDefinition[] = null;
  availableLanguages: CodeTableEntry[];
  availableLanguagesAndContentDefinitions: Map<CodeTableEntry, ContentDefinition[]> = new Map<CodeTableEntry, ContentDefinition[]>();

  campaign: Campaign;
  story: Story;
  contents: Content[];
  featureFlags: FeatureFlags;
  autoSyncEnabled = false;
  autoSyncContent = false;
  autoSyncContentMsg: string;
  autoSyncFeature = false;

  contentColumns: ColDef[];
  contentGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    rowMultiSelectWithClick: true,
    onRowClicked: (event: RowClickedEvent) => this.editContent(event.data),
  };

  private subscriptions: Subscription[] = [];

  constructor(
    protected readonly dataService: DataService,
    protected readonly modalService: ModalService,
    protected readonly codeTableService: CodeTableService,
    protected readonly translateService: TranslateService,
    protected readonly featureFlagsService: FeatureFlagsService,
    private readonly documentTransferService: DocumentTransferService,
    private readonly permissionService: PermissionService,
    private readonly contentService: ContentService
  ) {
    this.loading$ = this.dataService.loading$;
    this.featureFlagsService.getFeatureFlags().subscribe({
      next: (d: FeatureFlags) => {
        this.featureFlags = d;
        this.updateData();
        this.updateColumnDefs();
      },
    });
  }

  private updateColumnDefs() {
    this.contentColumns = this.generateColDefs();
  }
  private updateData() {
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.contents = [...this.campaign.contents];
          if (this.allLanguages) {
            this.calculateAvailableLanguagesAndChannels();
          }
          this.autoSyncFeature = this.campaign.story !== null;
          const isCampaignClosed =
            this.campaign.status === CampaignStatus.CLOSED ||
            this.campaign.status === CampaignStatus.TERMINATED;
          this.autoSyncEnabled =
            !isCampaignClosed &&
            !this.campaign.story?.autoSyncContent &&
            this.permissionService.hasAnyPermission(
              EProtectedActions.autoSyncCampaign
            );
          this.autoSyncContent =
            this.campaign.autoSyncContent ||
            this.campaign.story?.autoSyncContent;
          if (this.autoSyncEnabled) {
            this.autoSyncContentMsg = this.translateService.instant(
              this.autoSyncContent
                ? 'autoSyncToStoryDisable'
                : 'autoSyncToStoryEnable'
            );
          }
        })
    );

    this.subscriptions.push(
      this.dataService.story$
        .pipe(filter((story) => !!story))
        .subscribe((story) => {
          this.story = story;
          this.contents = [...this.story.contents];
          if (this.allLanguages) {
            this.calculateAvailableLanguagesAndChannels();
          }
          this.autoSyncFeature = true;
          this.autoSyncEnabled = this.permissionService.hasAnyPermission(
            EProtectedActions.autoSyncStory
          );
          this.autoSyncContent = this.story.autoSyncContent;
          this.autoSyncContentMsg = this.translateService.instant(
            this.autoSyncContent
              ? 'autoSyncToCampaignsDisable'
              : 'autoSyncToCampaignsEnable'
          );
        })
    );
  }

  ngOnInit(): void {
    this.codeTableService
      .getCodeTable(ECodeTables.language)
      .subscribe((data) => {
        this.allLanguages = data;
        if (this.campaign || this.story) {
          this.calculateAvailableLanguagesAndChannels();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get campaignStatus() {
    return CampaignStatus;
  }

  calculateAvailableLanguagesAndChannels(): void {
    if (!this.allContentDefinitions) {
      this.contentService.getContentDefinitions(this.story?.id ?? this.campaign.id,
        this.story ? EReferenceType.story : EReferenceType.campaign)
        .subscribe((data) => {
          this.allContentDefinitions = data;
          this.updateLanguageChannelMap();
        });
    } else {
      this.updateLanguageChannelMap();
    }
  }

  updateLanguageChannelMap(): void {
    this.allLanguages.forEach((lang) => {
      this.availableLanguagesAndContentDefinitions.set(lang, this.allContentDefinitions)
    });
    this.availableLanguagesAndContentDefinitions.forEach((_, lang, map) => {
      const contents = this.contents.filter((content) => content.language.id === lang.id);
      map.set(lang, this.allContentDefinitions.filter((contentDefinition) =>
          !contents.find((content) => content.contentDefinitionId == contentDefinition.id))
      );
      if (map.get(lang).length === 0) map.delete(lang);
    });
    this.availableLanguages = [...this.availableLanguagesAndContentDefinitions.keys()];
  }

  addContent(language: CodeTableEntry, contentDefinition: ContentDefinition): void {
    const targetId = this.campaign ? this.campaign.id : this.story.id;
    const contentImgHandling = this.campaign? this.campaign.contentImageHandling : this.story.contentImageHandling;
    this.addOrEditContent.emit(
      new ContentData(
        ActionType.CampaignAction,
        language,
        targetId,
        null,
        this.allowAddOrEditContent,
        null,
        null,
        contentDefinition.id,
        contentImgHandling,
      )
    );
  }

  editContent(content: Content): void {
    if (!this.allowAddOrEditContent) { // if user is not allowed to edit content, return
      return;
    }
    const targetId = this.campaign ? this.campaign.id : this.story.id;
    const contentImgHandling = this.campaign? this.campaign.contentImageHandling : this.story.contentImageHandling;
    this.addOrEditContent.emit(
      new ContentData(
        ActionType.CampaignAction,
        content.language,
        targetId,
        content,
        this.allowAddOrEditContent,
        null,
        null,
        content.contentDefinitionId,
        contentImgHandling,
      )
    );
  }

  previewContent(content: Content): void {
    const contentPreviewParams = {
      id: content.id,
      storyId: this.story ? this.story.id : undefined,
      campaignId: this.campaign ? this.campaign.id : undefined,
      channelTypesToShow: content.channelTypes.map(d => d.ident),
    } as ContentPreviewParams;
    const modalData: ModalData = {
      type: EModalType.previewContent,
      title: 'contentPreview',
      data: { contentPreviewParams },
      submitBtn: null,
      component: ContentPreviewComponent,
    };
    this.modalService.openDefaultDialog(modalData, 'custom-content-dialog');
  }

  restoreCampaignContent(): void {
    this.restoreContent.emit();
  }

  private generateColDefs() {
    const cols = [
      this.allowAddOrEditContent
        ? {
          ...genIconButtonColumn({
            callback: (contentData: Content) => this.editContent(contentData),
            icon: 'edit_m',
            tooltip: this.translateService.instant('editContent'),
          }),
          sortable: false,
        }
          : undefined,
      {
        ...genIconButtonColumn({
          callback: (contentData: Content) => this.previewContent(contentData),
          icon: 'preview',
          tooltip: this.translateService.instant('preview'),
        }),
        sortable: false,
      },
      this.allowAddOrEditContent
          ? {
      ...genIconButtonColumn({
            callback: (contentData: Content) =>
              this.deleteContent.emit(contentData.id),
            icon: 'delete',
            tooltip: this.translateService.instant('deleteContent'),
          }),
          sortable: false,
        }
          : undefined,
      {
        field: 'language.name',
        headerName: this.translateService.instant('language'),
        suppressHeaderMenuButton: true,
        resizable: true,
        floatingFilter: false,
      },
      {
        ...genIconButtonColumn({
          callback: (contentData: Content) => this.downloadPdf(contentData),
          icon: 'attach_file',
          hidden: (contentData: Content) => contentData.attachments.length == 0,
          tooltip: this.translateService.instant('download'),
        }),
        sortable: false,
      },
      {
        field: 'contentDefinitionName',
        headerName: this.translateService.instant('definition'),
        suppressHeaderMenuButton: true,
        resizable: true,
        sortable: false,
        floatingFilter: false,
      },
      {
        field: 'channelTypes',
        headerName: this.translateService.instant('channels'),
        suppressHeaderMenuButton: true,
        resizable: true,
        sortable: false,
        floatingFilter: false,
        valueFormatter: (params) => params.value.map(d => d.name).join(', '),
      },
    ].filter((d) => d); // remove null or undefined
    return cols;
  }

  downloadPdf(content: Content) {
    this.documentTransferService.downloadContentAttachment(
      content,
      this.modalService,
      this.documentTransferService
    );
  }

  handleToggleAutoSyncContent(event: any) {
    const target = this.campaign || this.story;
    event.source.checked = target.autoSyncContent;
    this.toggleAutoSync.emit();
  }
}
