import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProgressBarMode} from '@angular/material/progress-bar';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {OAuthService} from 'angular-oauth2-oidc';
import {Observable, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {
  ActionOperationType,
  AssigneeTargetType,
  BulkEditAvailables,
  Campaign,
  CampaignAction,
  CampaignActionService,
  CampaignIntermediaryAction,
  CampaignIntermediaryActionService,
  CampaignPortfolio,
  CampaignStatus,
  CodeTableEntry,
  PieDataItem,
  User,
} from 'src/app/api/core';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {NotificationService} from 'src/app/services/notification.service';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EChartTypes, ECodeTables, EModalType} from 'src/app/util/enum';
import {CustomPreviewService} from '../../../../services/custom-preview.service';
import {I18n} from '../../../../services/i18n.service';
import {genIconButtonColumn} from '../../../../shared/grid/cell-renderers/icon-button.renderer';
import {
  CampaignProcessingProgress,
  CampaignProcessingProgressSocket,
} from '../../../../util/campaign-processing-progress-socket';
import {
  genCodeTableColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel
} from '../../../../util/grid/grid-renderer.util';
import {ActionType, ContentAction} from "../campaign-actions-list-utils";
import {EProtectedActions} from "../../../../util/protected-actions";
import {PermissionService} from "../../../../services/permission.service";
import {CodeTableService} from "../../../../services/code-table.service";

type SelectedRow = {
  action: CampaignAction | CampaignIntermediaryAction;
  portfolio: CampaignPortfolio;
};

@Component({
  selector: 'app-campaign-action-processing',
  templateUrl: './campaign-action-processing.component.html',
})
export class CampaignActionProcessingComponent
  implements OnInit, OnDestroy, ModalSubComponent
{
  modalType: EModalType;
  campaign: Campaign;
  selectedCampaignActions: number[];
  actionType: ActionType = ActionType.CampaignAction;
  isHierarchyList = true;

  showProgress = false;
  progressValue = -1;
  progressBarMode: ProgressBarMode = 'indeterminate';

  private socket: CampaignProcessingProgressSocket;

  private modalLabels = [];

  columnDefs: ColDef[] = [];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
  };

  subscriptions: Subscription[] = [];
  userHubs: string[] = [];
  selectedRows: SelectedRow[] = [];
  selectedCounts = {
    portfolios: 0,
    clients: 0,
    intermediaries: 0,
    employees: 0
  };
  pieChannel: PieDataItem[];
  pieLanguage: PieDataItem[];
  filterSenders: string[] = [];

  bulkEdit = false;
  languages: CodeTableEntry[] = [];
  channelTypes: CodeTableEntry[] = [];
  senders: User[] = [];
  language = '';
  channelType = '';
  sender = '';
  gridApi: GridApi;

  constructor(
    private readonly oauthService: OAuthService,
    private campaignActionService: CampaignActionService,
    private campaignIntermediaryActionService: CampaignIntermediaryActionService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private codeTableService: CodeTableService,
    private readonly customPreviewService: CustomPreviewService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modalType = data.data.modalType;
    // define the action type based on the presence of intermediary
    this.actionType = data.data.selectedRows.some(r => r.intermediaryId) ?
      ActionType.IntermediateAction : ActionType.CampaignAction;
    this.selectedRows = data.data.selectedRows;
    this.selectedCampaignActions = this.selectedRows.map((r) => r.action.id);
    this.campaign = data.data.campaign;
    this.bulkEdit = this.modalType === EModalType.bulkEdit;
    this.selectedCounts = this.updateCounts(this.selectedRows);

    this.filterSenders = [...new Set(this.selectedRows.map((r) => r.action.sender.username))];

    this.columnDefs = this.generateColumnDefs();
    this.modalLabels = this.generateModalLabels();
    // we add the to-string to avoid binding issues
    this.dialogRef.componentInstance.title = this.getTitle().toString();
    this.dialogRef.componentInstance.submitBtn.label = this.getSubmitLabel().toString();
    this.isHierarchyList = data.data.isHierarchyList;
  }

  private updateCounts(rows: SelectedRow[]): any {
    // begin-update counts and charts
    this.pieChannel = this.getPieData(
      (row) =>
        row.action.channel?.type.name ?? this.translateService.instant('none')
    );
    this.pieLanguage = this.getPieData((row) => row.action.language.name);
    // end-update counts and charts
    const portfolios = Array.from(
      new Set(rows.filter(r => r.portfolio).map(r => r.portfolio?.id))
    ).length;
    const clients = Array.from(
      new Set(rows.filter(r => r.action['client']).map(r => r.action['client']?.id))
    ).length;
    const intermediaries = Array.from(
      new Set(rows.filter(r => r['employee']).map(r => r['intermediaryId']))
    ).length;
    const employees = Array.from(
      new Set(rows.filter(r => r['employee']).map(r => r['employee'].id))
    ).length;
    return {...this.selectedCounts, portfolios, clients, intermediaries, employees };
  }

  private generateColumnDefs(): ColDef[] {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    let cols: ColDef[];
    if (this.actionType == ActionType.CampaignAction) {
      cols = [
        this.campaign.status === CampaignStatus.LAUNCHED ? {
          ...genIconButtonColumn({
            hidden: (data: SelectedRow) =>
              !data.action.channel ||
              !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent) ||
              !data.action.hasCidPermission,
            callback: (data: SelectedRow) => {
              this.showPreview(data);
            },
            tooltip: this.translateService.instant('viewContent'),
            icon: 'preview',
          }),
        } : undefined,
        {
          ...genTextColumn(
            'action.client.ident',
            I18n.getColName('actionRecipientId')
          ),
          floatingFilter: true,
          sortable: true,
          hide: true,
        },
        {
          ...genTextColumnWithAutoCompleteFilter({
            field: 'action.client.fullName',
            headerName: I18n.getColName('actionRecipientName'),
            autoCompleteParams: this.selectedRows.map(d =>
              (d.action as CampaignAction).client.fullName)
          }),
          floatingFilter: isCidFilterAllowed,
          sortable: isCidFilterAllowed,
        },
        genCodeTableColumn({
          field: 'action.preferredLanguage',
          headerName: I18n.getColName('languagePreferred'),
          observable: this.codeTableService.getCodeTable(ECodeTables.language),
        }),
        genCodeTableColumn({
          field: 'action.language',
          headerName: I18n.getColName('languageActual'),
          observable: this.codeTableService.getCodeTable(ECodeTables.language),
        }),
        genCodeTableColumn({
          field: 'action.channel.type',
          headerName: I18n.getColName('actionChannel'),
          observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
          filterHubs: () => this.userHubs
        }),
        {
          ...genTextColumn(
            'portfolio.bpName',
            I18n.getColName('actionBpName')
          ),
          floatingFilter: isCidFilterAllowed,
          sortable: isCidFilterAllowed,
        },
        {
          ...genTextColumnWithAutoCompleteFilter({
            field: 'portfolio.number',
            headerName: I18n.getColName('actionPortfolio'),
            autoCompleteParams: this.selectedRows.map(d =>
              (d.action as CampaignAction).portfolio.number)
          }),
          floatingFilter: true,
          sortable: true,
        },
        {
          ...genUserEnumColumn(
            'action.sender.username',
            I18n.getColName('sender'),
            (params) => params.success(this.filterSenders),
            () => this.data.data.selectedRows.map((r) => r.action.sender)
          ),
          valueFormatter: (r) => usernameValueLabel(r.data.action.sender),
          floatingFilter: true,
          sortable: true,
        },
      ];
    } else { // is intermediary action
      cols = [
        {
          ...genIconButtonColumn({
            hidden: (data: SelectedRow) =>
              !data.action.channel ||
              !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent) ||
              !data.action.hasCidPermission,
            callback: (data: SelectedRow) => {
              this.showPreview(data);
            },
            tooltip: this.translateService.instant('viewContent'),
            icon: 'preview',
          }),
        },
        {
          ...genTextColumn(
            'action.employee.ident',
            I18n.getColName('ident')
          ),
          floatingFilter: true,
          sortable: true,
          hide: true,
        },
        {
          ...genTextColumn(
            'action.employee.name',
            I18n.getColName('employeeName')
          ),
          floatingFilter: isCidFilterAllowed,
          sortable: isCidFilterAllowed,
        },
        genCodeTableColumn({
          field: 'action.language',
          headerName: I18n.getColName('languageActual'),
          observable: this.codeTableService.getCodeTable(ECodeTables.language),
        }),
        genCodeTableColumn({
          field: 'action.channel.type',
          headerName: I18n.getColName('actionChannel'),
          observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
          filterHubs: () => this.userHubs
        }),
        {
          ...genUserEnumColumn(
            'action.sender.username',
            I18n.getColName('sender'),
            (params) => params.success(this.filterSenders),
            () => this.data.data.selectedRows.map((r) => r.action.sender)
          ),
          valueFormatter: (r) => usernameValueLabel(r.data.action.sender),
          floatingFilter: true,
          sortable: true,
        },
      ];
    }
    // remove undefined columns
    return cols.filter(c => c);
  }

  private initData() {
    if (this.bulkEdit) {
      this.gridApi.showLoadingOverlay();
      let serviceCall: Observable<BulkEditAvailables>;
      if (this.actionType === ActionType.CampaignAction) {
       serviceCall = this.campaignActionService
         .getBulkEditOptions({ actionIds: this.selectedCampaignActions });
      } else {
        serviceCall = this.campaignIntermediaryActionService
          .getBulkEditOptions({ actionIds: this.selectedCampaignActions });
      }
      serviceCall.pipe(first())
        .subscribe((dto) => {
          this.languages = dto.languages || [];
          this.channelTypes = dto.channelTypes || [];
          this.senders = dto.senders || [];
          this.gridApi.hideOverlay();
        });
    }
  }

  ngOnInit(): void {
    if (this.bulkEdit) {
      this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
      return;
    }
    this.socket = new CampaignProcessingProgressSocket(
      this.campaign.id,
      this.oauthService.getAccessToken()
    );
    this.socket
      .connect()
      .subscribe((progress) => this.handleProgress(progress));
    this.subscriptions.push(
      this.permissionService.user$.subscribe((user) => {
        this.userHubs = user.hubs.map((h) => h.ident);
      })
    );
  }

  ngOnDestroy(): void {
    this.socket?.close();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get chartTypes() {
    return EChartTypes;
  }

  private getPieData(cbKey: (row: SelectedRow) => string): PieDataItem[] {
    const res: Record<string, number> = {};
    this.selectedRows.forEach((row) => {
      const key = cbKey(row);
      res[key] = (res[key] || 0) + 1;
    });
    return Object.entries(res).map(([key, value]) => ({ key, value }));
  }

  private handleProgress(progress: CampaignProcessingProgress) {
    if (progress.error) {
      this.notificationService.handleError(progress.processingMessage);
      this.dialogRef.close();
      return;
    }
    if (progress.total === -1) {
      this.progressBarMode = 'indeterminate';
    } else {
      this.progressBarMode = 'determinate';
    }
    this.progressValue = Math.round((progress.current * 100) / progress.total);
    if (this.showProgress && !progress.processing) {
      this.notificationService.handleSuccess(this.getSuccessMessage());
      this.dialogRef.close(true);
    }
  }

  modalAction(modalType: EModalType): void {
    let func: (arr: Array<number>) => Observable<any>;
    switch (modalType) {
      case EModalType.executeActionsDialog:
        func = (arr) => this.executeActions(arr);
        break;
      case EModalType.revertActionsDialog:
        func = (arr) => this.revertActions(arr);
        break;
      case EModalType.assignActionsToAdvisorDialog:
        func = (arr) =>
          this.campaignActionService.campaignActionsAssign(AssigneeTargetType.ADVISOR, arr);
        break;
      case EModalType.assignActionsToRelationshipManagerDialog:
        func = (arr) =>
          this.campaignActionService.campaignActionsAssign(
            AssigneeTargetType.RELATIONSHIPMANAGER,
            arr
          );
        break;
      case EModalType.setNoActionsDialog:
        func = (arr) => this.setNoActions(arr);
        break;
      case EModalType.removeActionsDialog:
        func = (arr) => this.deleteActions(arr);
        break;
      case EModalType.refreshSuitabilitiesDialog:
        func = (arr) => this.campaignActionService.refreshSuitabilities(arr)
          .pipe(map(() => this.dialogRef.close(true)));
        break;
      case EModalType.bulkEdit:
        func = (arr) =>
          this.executeBulkEdit(arr);
        break;
      default:
        this.dialogRef.close();
        break;
    }
    if (func) {
      this.performActionsProcessing(this.selectedCampaignActions, func);
    }
  }

  // operations
  private executeActions(arr: Array<number>): Observable<any> {
    if (this.actionType === ActionType.CampaignAction) {
      return this.campaignActionService.executeActions(
        {
          setNoActions: this.isHierarchyList,
          actionIds: arr,
        }
      );
    } else {
      return this.campaignIntermediaryActionService.executeOperation(
        {
          operation: ActionOperationType.EXECUTE,
          items: arr,
        }
      );
    }
  }

  private revertActions(arr: Array<number>): Observable<any> {
    if (this.actionType === ActionType.CampaignAction) {
      return this.campaignActionService.campaignActionsRevert(arr);
    } else {
      return this.campaignIntermediaryActionService.executeOperation(
        {
          operation: ActionOperationType.REVERT,
          items: arr,
        }
      );
    }
  }

  private setNoActions(arr: Array<number>): Observable<any> {
    if (this.actionType === ActionType.CampaignAction) {
      return this.campaignActionService.campaignActionsNoAction(arr);
    } else {
      return this.campaignIntermediaryActionService.executeOperation(
        {
          operation: ActionOperationType.NOACTION,
          items: arr,
        }
      );
    }
  }

  private deleteActions(arr: Array<number>): Observable<any> {
    if (this.actionType === ActionType.CampaignAction) {
      return this.campaignActionService.deleteCampaignActions(arr);
    } else {
      return this.campaignIntermediaryActionService.executeOperation(
        {
          operation: ActionOperationType.DELETE,
          items: arr,
        }
      );
    }
  }

  private executeBulkEdit(arr: Array<number>): Observable<any> {
    if (this.actionType === ActionType.CampaignAction) {
      return this.campaignActionService.executeBulkEdit({
        actionIds: arr,
        language: this.language,
        channelType: this.channelType,
        sender: this.sender,
      });
    } else {
      return this.campaignIntermediaryActionService.executeBulkEdit({
        actionIds: arr,
        language: this.language,
        channelType: this.channelType,
        sender: this.sender,
      });
    }
  }

  private performActionsProcessing(
    actions: Array<number>,
    func: (arr: Array<number>) => Observable<any>
  ) {
    this.showProgress = true;
    this.progressValue = 0;

    if (this.bulkEdit) {
      this.progressBarMode = 'indeterminate';
      func(actions).subscribe({
        next: (updatedActions) => {
          this.dialogRef.close({ updatedActions });
          this.notificationService.handleSuccess(
            this.translateService.instant('bulkEditSuccess')
          );
        },
      });
    } else {
      func(actions).pipe(first()).subscribe({
        next: () => {
          this.progressValue = 10;
        },
        error: (err) => {
          this.notificationService.handleError(
            err.error ? err.error.error : err.message
          );
          this.dialogRef.close();
        },
      });
    }
  }

  private getLabels(
    type: CampaignProcessingLabelType,
    modalType: EModalType,
    count: number
  ) {
    const labels = this.modalLabels.find(
      (t) => t.modalType === modalType
    ).labels;
    const keys = labels.find((t) => t.type === type).keys;
    return {
      singular: this.translateService.instant(keys[0]),
      plural: this.translateService.instant(keys[1], { count }),
    };
  }

  private getTitle(): string {
    const msg = this.getLabels(
      CampaignProcessingLabelType.TITLE,
      this.modalType,
      this.selectedCampaignActions.length
    );
    return this.selectedCampaignActions.length === 1
      ? msg.singular
      : msg.plural;
  }

  private getSuccessMessage(): string {
    const msg = this.getLabels(
      CampaignProcessingLabelType.SUCCESS_MESSAGE,
      this.modalType,
      this.selectedCampaignActions.length
    );
    return this.selectedCampaignActions.length === 1
      ? msg.singular
      : msg.plural;
  }

  private getSubmitLabel(): string {
    let label: string;
    switch (this.modalType) {
      case EModalType.executeActionsDialog:
        label = this.translateService.instant('execute');
        break;
      case EModalType.revertActionsDialog:
        label = this.translateService.instant('revertAction');
        break;
      case EModalType.assignActionsToAdvisorDialog:
        label = this.translateService.instant('assignToAdvisor');
        break;
      case EModalType.assignActionsToRelationshipManagerDialog:
        label = this.translateService.instant('assignToRelationshipManager');
        break;
      case EModalType.setNoActionsDialog:
        label = this.translateService.instant('setNoAction');
        break;
      case EModalType.removeActionsDialog:
        label = this.translateService.instant('remove');
        break;
      case EModalType.refreshSuitabilitiesDialog:
        label = this.translateService.instant('updateSuitability');
        break;
      case EModalType.bulkEdit:
        label = this.translateService.instant('update');
        break;
      default:
        label = '';
        break;
    }
    return label;
  }
  private showPreview(data: SelectedRow) {
    const isIntermediary = data['intermediaryId'];
    const contentAction: ContentAction = {
      id: data.action.id,
      type: isIntermediary ? ActionType.IntermediateAction: ActionType.CampaignAction,
      campaignId: this.campaign.id,
      language: data.action.language,
      channel: data.action.channel,
      content: data.action.content,
    }
    this.customPreviewService.previewCustomContent(
        contentAction,
        isIntermediary,
        true);
  }

  private generateModalLabels() {
    return [
      {
        modalType: EModalType.executeActionsDialog,
        labels: [
          {
            type: CampaignProcessingLabelType.TITLE,
            keys: ['executeAction?', 'executeActions?'],
          },
          {
            type: CampaignProcessingLabelType.SUCCESS_MESSAGE,
            keys: ['executeActionSuccess', 'executeActionsSuccess'],
          },
        ],
      },
      {
        modalType: EModalType.revertActionsDialog,
        labels: [
          {
            type: CampaignProcessingLabelType.TITLE,
            keys: this.actionType === ActionType.CampaignAction ?
              ['revertClientAction?', 'revertClientActions?']
            : ['revertAction', 'revertActions'],
          },
          {
            type: CampaignProcessingLabelType.SUCCESS_MESSAGE,
            keys: this.actionType === ActionType.CampaignAction ?
              ['revertClientActionSuccess', 'revertClientActionSuccess']
            : ['revertActionSuccess', 'revertActionsSuccess'],
          },
        ],
      },
      {
        modalType: EModalType.assignActionsToAdvisorDialog,
        labels: [
          {
            type: CampaignProcessingLabelType.TITLE,
            keys: ['assignActionToAdvisor?', 'assignActionsToAdvisor?'],
          },
          {
            type: CampaignProcessingLabelType.SUCCESS_MESSAGE,
            keys: [
              'assignActionToAdvisorSuccess',
              'assignActionsToAdvisorSuccess',
            ],
          },
        ],
      },
      {
        modalType: EModalType.assignActionsToRelationshipManagerDialog,
        labels: [
          {
            type: CampaignProcessingLabelType.TITLE,
            keys: [
              'assignActionToRelationshipManager?',
              'assignActionsToRelationshipManager?',
            ],
          },
          {
            type: CampaignProcessingLabelType.SUCCESS_MESSAGE,
            keys: [
              'assignActionToRelationshipManagerSuccess',
              'assignActionsToRelationshipManagerSuccess',
            ],
          },
        ],
      },
      {
        modalType: EModalType.setNoActionsDialog,
        labels: [
          {
            type: CampaignProcessingLabelType.TITLE,
            keys: this.actionType === ActionType.CampaignAction ?
              ['setClientNoAction?', 'setClientsNoAction?']
            : ['setItemNoAction?', 'setItemsNoAction?'],
          },
          {
            type: CampaignProcessingLabelType.SUCCESS_MESSAGE,
            keys: this.actionType === ActionType.CampaignAction ?
              ['setClientNoActionSuccess', 'setClientsNoActionSuccess']
            : ['setItemNoActionSuccess', 'setItemNoActionsSuccess'],
          },
        ],
      },
      {
        modalType: EModalType.removeActionsDialog,
        labels: [
          {
            type: CampaignProcessingLabelType.TITLE,
            keys: ['removeSelectedRecipient?', 'removeSelectedRecipients?'],
          },
          {
            type: CampaignProcessingLabelType.SUCCESS_MESSAGE,
            keys: [
              'removeSelectedRecipientSuccess',
              'removeSelectedRecipientsSuccess',
            ],
          },
        ],
      },
      {
        modalType: EModalType.refreshSuitabilitiesDialog,
        labels: [
          {
            type: CampaignProcessingLabelType.TITLE,
            keys: [
              'refreshSelectedSuitability?',
              'refreshSelectedSuitabilities?',
            ],
          },
          {
            type: CampaignProcessingLabelType.SUCCESS_MESSAGE,
            keys: [
              'refreshSelectedSuitabilitySuccess',
              'refreshSelectedSuitabilitiesSuccess',
            ],
          },
        ],
      },
      {
        modalType: EModalType.bulkEdit,
        labels: [
          {
            type: CampaignProcessingLabelType.MESSAGE,
            keys: ['bulkEditDialogMessage', 'bulkEditDialogMessage'],
          },
          {
            type: CampaignProcessingLabelType.TITLE,
            keys: ['bulkEdit', 'bulkEdit'],
          },
          {
            type: CampaignProcessingLabelType.SUCCESS_MESSAGE,
            keys: ['bulkEditSuccess', 'bulkEditSuccess'],
          },
        ],
      },
    ];
  }

  protected readonly ActionType = ActionType;
  protected readonly EModalType = EModalType;

  private gridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.initData();
    this.subscriptions.push(I18n.getColumns(this.translateService, this.gridApi));
    this.gridApi.resetRowHeights();
  }

  onBulkEditUpdate() {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !(this.language || this.channelType || this.sender);
  }
}

enum CampaignProcessingLabelType {
  TITLE,
  SUCCESS_MESSAGE,
  MESSAGE,
}
