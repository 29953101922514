import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {
  AvailableChannel,
  BulkEditAvailables,
  CampaignAction,
  CampaignActionService,
  ChannelService,
  CodeTableEntry,
  User
} from "../../../../api/core";
import {combineLatest, Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {FormBuilder} from "@angular/forms";
import {ModalSubComponent} from "../../../../models/modal.model";
import {EModalType} from "../../../../util/enum";

@Component({
  selector: 'app-campaign-action-edit',
  templateUrl: './campaign-action-edit.component.html'
})
export class CampaignActionEditComponent implements OnInit, OnDestroy, ModalSubComponent {
  action: CampaignAction;
  availables: BulkEditAvailables;
  channels: AvailableChannel[];
  actionForm = this.formBuilder.group({
    language: [null as CodeTableEntry],
    channel: [null as AvailableChannel],
    sender: [null as User],
  });
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    protected readonly channelService: ChannelService,
    protected readonly campaignActionService: CampaignActionService,
    protected readonly dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.action = data.data.action;
  }

  ngOnInit() {
    combineLatest([
      this.campaignActionService.getBulkEditOptions({actionIds: [this.action.id]}),
      this.channelService.getAvailableChannels(this.action.portfolio.ident, this.action.client.ident)
    ]).subscribe(([availables, channels]) => {
      this.availables = availables;
      this.channels = channels;
      this.actionForm.patchValue({
        language: this.availables.languages.find(l => l.id === this.action.language.id),
        channel: this.channels.find(c => c.type.id === this.action.channel.type.id),
        sender: this.availables.senders.find(s => s.id === this.action.sender.id),
      });
      // disable senders if no senders are available
      if (this.availables.senders.length === 0) {
        this.actionForm.get('sender')?.disable();
      }
    });
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
    this.subscriptions.push(
      this.actionForm.statusChanges.subscribe(() => {
        const isUpdated = this.action.channel.type.id !== this.actionForm.get('channel')?.value.type.id
          || this.action.language.id !== this.actionForm.get('language')?.value?.id
          || this.action.sender?.id !== this.actionForm.get('sender')?.value?.id;
        this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !isUpdated;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  modalAction(modalType?: EModalType) {
    const update = {
      actionIds: [this.action.id],
      language: this.actionForm.value.language.ident,
      channelType: this.actionForm.value.channel.type.ident,
      sender: this.actionForm.value.sender?.username,
    };
    this.campaignActionService.executeBulkEdit(update).subscribe(() => {
      this.dialogRef.close({
        ...this.action,
        language: this.actionForm.value.language,
        channel: {
          ...this.action.channel,
          type: this.actionForm.value.channel.type,
        },
        sender: this.actionForm.value.sender ? this.actionForm.value.sender : this.action.sender,
      } as CampaignAction);
    });
  }
}
