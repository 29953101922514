<app-campaign-overview-processing *ngIf="!campaign || campaign.processing"
></app-campaign-overview-processing>
<div *ngIf="campaign && !campaign.processing" class="page-content-container">
  <app-tabs-panel
    [tabs]="tabs"
    [activeTab]="activeTab"
    [defaultTab]="defaultTab"
    (tabChanged)="onTabChanged($event)"
  ></app-tabs-panel>

  <div *ngIf="activeTab === tabs[0].text" class="tab-content-container">
    <app-campaign-compact-content (next)="onNext()"></app-campaign-compact-content>
  </div>

  <div *ngIf="activeTab === tabs[1].text" class="tab-content-container">
    <app-campaign-compact-send
      *ngIf="useCompactSend"
      [campaign]="campaign"
      (viewSwitched)="switchToCompactOverview()"
      (onBack)="activeTab = tabs[0].text"
    >
    </app-campaign-compact-send>
    <app-campaign-send
      *ngIf="!useCompactSend"
      (viewSwitched)="switchToCompactOverview()">
    </app-campaign-send>
  </div>
  <div *ngIf="activeTab === tabs[2].text" class="intermediary-list-container">
    <app-campaign-action-toolbar
      #campaign_intermediary_toolbar
      [hideSwitch]="true"
      [actionType]="ActionType.IntermediateAction"
      [pageButtons]="intermediaryButtons"
    ></app-campaign-action-toolbar>
    <app-campaign-intermediary-list
      [actionToolbar]="campaign_intermediary_toolbar"
      [onCollapseAll]="collapseAll$"
      [onExpandAll]="expandAll$">
    </app-campaign-intermediary-list>
  </div>
</div>
