import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {TranslateService} from '@ngx-translate/core';
import {Block, MenuGenerator} from 'blockly';
import {Order} from 'blockly/javascript';
import {AdvancedFilterField} from 'src/app/models/filter.model';
import * as Blockly from 'blockly';

export class BlockFilterLike extends BlockDefinition {
  constructor(ident: string, readonly fields: AdvancedFilterField[], readonly outputCheckTypes: string[] = ['Boolean']) {
    super(
      'filter_field-like',
      ident,
      fields.some((d) => d.active)
    );
  }

  init(translateService: TranslateService, block: Block, color: string) {
    const options: MenuGenerator = this.fields
      .filter((f) => f.active)
      .map((d) => [d.name, d.ident]);
    block
      .appendDummyInput('field_input')
      .appendField(translateService.instant(this.ident) + ':')
      .appendField(
        new Blockly.FieldDropdown(
          options,
          this.dropdownValidator(block).bind(this)
        ),
        'field'
      )
      .appendField(translateService.instant('like'))
      .appendField(
        this.fieldText('',null),
        'field_value'
      );
    block.setInputsInline(true);
    block.setNextStatement(false, null);
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const field = block.getFieldValue('field');
    const value = block.getFieldValue('field_value');
    return {
      result: {
        type: 'like',
        name: field,
        valueStr: value,
      },
      order: Order.NONE,
    };
  }

  dropdownValidator(block: Block) {
    const self = this;
    return (newValue: any) => {
      block.removeInput('input_suffix', true);
      const field = self.fields.find((d) => d.ident === newValue);
      const uiField = block.getField('field_value') as any;
      if (uiField.setMin && field.min != null) {
        uiField.setMin(field.min);
      }
      if (uiField.setMax && field.max != null) {
        uiField.setMax(field.max);
      }

      if (field.suffix) {
        block.appendDummyInput('input_suffix').appendField(field.suffix);
      }
    };
  }
}
