import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {CampaignActionService, SuitabilityStatus} from 'src/app/api/core';
import {GridStateEnum, GridSuitabilityIconAndColorClass,} from 'src/app/models/grid.model';
import {genSuitabilityColumn, getIconAndColor,} from 'src/app/shared/grid/cell-renderers/suitability.renderer';
import {genTextColumn, genTextColumnWithAutoCompleteFilter} from 'src/app/util/grid/grid-renderer.util';

@Component({
  selector: 'app-campaign-suitability-summary',
  templateUrl: './campaign-suitability-summary.component.html',
})
export class CampaignSuitabilitySummaryComponent {
  combinedIconAndColorClass: GridSuitabilityIconAndColorClass;
  rowData: SuitabilityStatus[];
  gridApi: GridApi;

  detailsColumns: ColDef[] = [
    {
      ...genTextColumnWithAutoCompleteFilter({
        field: 'client.fullName',
        headerName: this.translateService.instant('client'),
        autoCompleteParams: () => this.rowData.map(d => d.client.fullName)
      }),
    },
    genTextColumn('product.name', this.translateService.instant('product')),
    genTextColumn('product.isin', this.translateService.instant('isin')),
    {
      ...genSuitabilityColumn({
        translateService: this.translateService,
        field: 'state',
        stateInfo: (data: SuitabilityStatus) => data,
        headerName: this.translateService.instant('state'),
      }),
      suppressHeaderMenuButton: true,
    },
    genTextColumn('category', this.translateService.instant('category')),
    {
      ...genTextColumn('detail', this.translateService.instant('detail')),
      tooltipField: 'detail',
    },
  ];
  detailsGridOptions: GridOptions = {
    rowHeight: 36,
    floatingFiltersHeight: 0,
    suppressContextMenu: true,
    suppressCellFocus: true,
    enableBrowserTooltips: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
  };

  constructor(
    protected translateService: TranslateService,
    protected campaignActionService: CampaignActionService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: { state: GridStateEnum; campaignPortfolioId: number };
    }
  ) {
    this.combinedIconAndColorClass = getIconAndColor(data.data.state);

    this.campaignActionService
      .getCampaignPortfolioSuitabilities(data.data.campaignPortfolioId)
      .subscribe((suitabilities) => this.handleData(suitabilities));
  }

  private handleData(status: SuitabilityStatus[]): void {
    this.rowData = status;
  }

  gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
  }
}
