/*! For license information please see index.js.LICENSE.txt */
!function (t, e) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = e(require("blockly/core"));else if ("function" == typeof define && define.amd) define(["blockly/core"], e);else {
    var n = "object" == typeof exports ? e(require("blockly/core")) : e(t.Blockly);
    for (var i in n) ("object" == typeof exports ? exports : t)[i] = n[i];
  }
}(this, t => (() => {
  "use strict";

  var e = {
      370: e => {
        e.exports = t;
      }
    },
    n = {};
  function i(t) {
    var s = n[t];
    if (void 0 !== s) return s.exports;
    var o = n[t] = {
      exports: {}
    };
    return e[t](o, o.exports, i), o.exports;
  }
  i.d = (t, e) => {
    for (var n in e) i.o(e, n) && !i.o(t, n) && Object.defineProperty(t, n, {
      enumerable: !0,
      get: e[n]
    });
  }, i.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e), i.r = t => {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(t, "__esModule", {
      value: !0
    });
  };
  var s = {};
  i.r(s), i.d(s, {
    blockIsDynamic: () => a,
    decoratePreviewer: () => p,
    finalizeConnections: () => c,
    overrideOldBlockDefinitions: () => h
  });
  var o = i(370);
  const l = {
    minInputs: 1,
    elseifCount: 0,
    elseCount: 0,
    init() {
      this.setHelpUrl(o.Msg.CONTROLS_IF_HELPURL), this.setStyle("logic_blocks"), this.addFirstCase(), this.setNextStatement(!0), this.setPreviousStatement(!0), this.setTooltip(o.Msg.LISTS_CREATE_WITH_TOOLTIP);
    },
    mutationToDom() {
      if (this.isDeadOrDying() || (o.Events.disable(), this.finalizeConnections(), this instanceof o.BlockSvg && this.initSvg(), o.Events.enable()), !this.elseifCount && !this.elseCount) return null;
      const t = o.utils.xml.createElement("mutation");
      return this.elseifCount && t.setAttribute("elseif", `${this.elseifCount}`), this.elseCount && t.setAttribute("else", "1"), t;
    },
    domToMutation(t) {
      t.getAttribute("inputs") ? this.deserializeInputs(t) : this.deserializeCounts(t);
    },
    deserializeInputs(t) {
      const e = t.getAttribute("inputs");
      if (e) {
        const t = e.split(",");
        this.getInput("IF0") && this.removeInput("IF0"), this.getInput("DO0") && this.removeInput("DO0");
        const n = t[0];
        this.appendValueInput("IF" + n).setCheck("Boolean").appendField(o.Msg.CONTROLS_IF_MSG_IF, "if"), this.appendStatementInput("DO" + n).appendField(o.Msg.CONTROLS_IF_MSG_THEN);
        for (let e = 1; e < t.length; e++) this.appendValueInput("IF" + t[e]).setCheck("Boolean").appendField(o.Msg.CONTROLS_IF_MSG_ELSEIF, "elseif"), this.appendStatementInput("DO" + t[e]).appendField(o.Msg.CONTROLS_IF_MSG_THEN);
      }
      "true" == t.getAttribute("else") && this.addElseInput();
    },
    deserializeCounts(t) {
      var e, n;
      this.elseifCount = parseInt(null !== (e = t.getAttribute("elseif")) && void 0 !== e ? e : "0", 10) || 0, this.elseCount = parseInt(null !== (n = t.getAttribute("else")) && void 0 !== n ? n : "0", 10) || 0;
      for (let t = 1; t <= this.elseifCount; t++) this.insertElseIf(this.inputList.length, t);
      this.elseCount && this.addElseInput();
    },
    saveExtraState: function () {
      if (this.isDeadOrDying() || this.isCorrectlyFormatted() || (o.Events.disable(), this.finalizeConnections(), this instanceof o.BlockSvg && this.initSvg(), o.Events.enable()), !this.elseifCount && !this.elseCount) return null;
      const t = Object.create(null);
      return this.elseifCount && (t.elseIfCount = this.elseifCount), this.elseCount && (t.hasElse = !0), t;
    },
    loadExtraState: function (t) {
      if ("string" != typeof t) {
        this.elseifCount = t.elseIfCount || 0, this.elseCount = t.hasElse ? 1 : 0;
        for (let t = 1; t <= this.elseifCount; t++) this.insertElseIf(this.inputList.length, t);
        this.elseCount && this.addElseInput();
      } else this.domToMutation(o.utils.xml.textToDom(t));
    },
    findInputIndexForConnection(t) {
      var e;
      if (!t.targetConnection || (null === (e = t.targetBlock()) || void 0 === e ? void 0 : e.isInsertionMarker())) return null;
      for (let e = 0; e < this.inputList.length; e++) if (this.inputList[e].connection == t) return e;
      return null;
    },
    insertElseIf(t, e) {
      const n = this.appendValueInput("IF" + e).setCheck("Boolean").appendField(o.Msg.CONTROLS_IF_MSG_ELSEIF, "elseif"),
        i = this.appendStatementInput("DO" + e).appendField(o.Msg.CONTROLS_IF_MSG_THEN);
      return this.moveInputBefore("IF" + e, this.inputList[t].name), this.moveInputBefore("DO" + e, this.inputList[t + 1].name), {
        ifInput: n,
        doInput: i
      };
    },
    onPendingConnection(t) {
      t.type !== o.NEXT_STATEMENT || this.getInput("ELSE") || this.addElseInput();
      const e = this.findInputIndexForConnection(t);
      if (null !== e && this.inputList[e].name.includes("IF")) {
        const t = this.inputList[e + 2];
        if (t && "ELSE" != t.name) {
          const n = t && t.connection && t.connection.targetConnection;
          n && !n.getSourceBlock().isInsertionMarker() && this.insertElseIf(e + 2, o.utils.idGenerator.genUid());
        } else this.insertElseIf(e + 2, o.utils.idGenerator.genUid());
      }
    },
    finalizeConnections() {
      var t, e, n;
      const i = this.collectTargetCaseConns(),
        s = null === (e = null === (t = this.getInput("ELSE")) || void 0 === t ? void 0 : t.connection) || void 0 === e ? void 0 : e.targetConnection;
      this.tearDownBlock(), this.addFirstCase(), this.addCaseInputs(i), s && (null === (n = this.addElseInput().connection) || void 0 === n || n.connect(s)), this.elseifCount = Math.max(i.length - 1, 0), this.elseCount = s ? 1 : 0;
    },
    collectTargetCaseConns() {
      var t, e;
      const n = [];
      for (let i = 0; i < this.inputList.length - 1; i += 2) {
        const s = null === (t = this.inputList[i].connection) || void 0 === t ? void 0 : t.targetConnection,
          o = null === (e = this.inputList[i + 1].connection) || void 0 === e ? void 0 : e.targetConnection;
        (s || o) && n.push({
          ifTarget: s,
          doTarget: o
        });
      }
      return n;
    },
    tearDownBlock() {
      for (let t = this.inputList.length - 1; t >= 0; t--) this.removeInput(this.inputList[t].name);
    },
    addCaseInputs(t) {
      var e, n;
      for (let i = 0; i < t.length; i++) {
        let s = this.getInput(`IF${i}`),
          o = this.getInput(`DO${i}`);
        s && o || ({
          ifInput: s,
          doInput: o
        } = this.insertElseIf(2 * i, i));
        const {
          ifTarget: l,
          doTarget: r
        } = t[i];
        l && (null === (e = s.connection) || void 0 === e || e.connect(l)), r && (null === (n = o.connection) || void 0 === n || n.connect(r));
      }
    },
    addElseInput() {
      return this.appendStatementInput("ELSE").appendField(o.Msg.CONTROLS_IF_MSG_ELSE);
    },
    addFirstCase() {
      this.appendValueInput("IF0").setCheck("Boolean").appendField(o.Msg.CONTROLS_IF_MSG_IF, "if"), this.appendStatementInput("DO0").appendField(o.Msg.CONTROLS_IF_MSG_THEN);
    },
    isCorrectlyFormatted() {
      for (let t = 0; t < this.inputList.length - 1; t += 2) {
        if (this.inputList[t].name !== `IF${t}`) return !1;
        if (this.inputList[t + 1].name !== `DO${t}`) return !1;
      }
      return !0;
    }
  };
  o.Blocks.dynamic_if = l;
  const r = {
    minInputs: 2,
    itemCount: 0,
    init() {
      this.itemCount = this.minInputs, this.setHelpUrl(o.Msg.TEXT_JOIN_HELPURL), this.setStyle("text_blocks"), this.addFirstInput();
      for (let t = 1; t < this.minInputs; t++) this.appendValueInput(`ADD${t}`);
      this.setOutput(!0, "String"), this.setTooltip(o.Msg.TEXT_JOIN_TOOLTIP);
    },
    mutationToDom() {
      this.isDeadOrDying() || (o.Events.disable(), this.finalizeConnections(), this instanceof o.BlockSvg && this.initSvg(), o.Events.enable());
      const t = o.utils.xml.createElement("mutation");
      return t.setAttribute("items", `${this.itemCount}`), t;
    },
    domToMutation(t) {
      t.getAttribute("inputs") ? this.deserializeInputs(t) : this.deserializeCounts(t);
    },
    deserializeInputs(t) {
      const e = t.getAttribute("inputs");
      if (e) {
        const t = e.split(",");
        this.inputList = [], t.forEach(t => this.appendValueInput(t)), this.inputList[0].appendField(o.Msg.TEXT_JOIN_TITLE_CREATEWITH);
      }
    },
    deserializeCounts(t) {
      var e;
      this.itemCount = Math.max(parseInt(null !== (e = t.getAttribute("items")) && void 0 !== e ? e : "0", 10), this.minInputs);
      for (let t = this.minInputs; t < this.itemCount; t++) this.appendValueInput("ADD" + t);
    },
    saveExtraState: function () {
      return this.isDeadOrDying() || this.isCorrectlyFormatted() || (o.Events.disable(), this.finalizeConnections(), this instanceof o.BlockSvg && this.initSvg(), o.Events.enable()), {
        itemCount: this.itemCount
      };
    },
    loadExtraState: function (t) {
      var e;
      if ("string" != typeof t) {
        this.itemCount = null !== (e = t.itemCount) && void 0 !== e ? e : 0;
        for (let t = this.minInputs; t < this.itemCount; t++) this.appendValueInput("ADD" + t);
      } else this.domToMutation(o.utils.xml.textToDom(t));
    },
    findInputIndexForConnection(t) {
      var e, n;
      if (!t.targetConnection || (null === (e = t.targetBlock()) || void 0 === e ? void 0 : e.isInsertionMarker())) return null;
      let i = -1;
      for (let e = 0; e < this.inputList.length; e++) this.inputList[e].connection == t && (i = e);
      if (i == this.inputList.length - 1) return this.inputList.length + 1;
      const s = this.inputList[i + 1],
        o = null === (n = null == s ? void 0 : s.connection) || void 0 === n ? void 0 : n.targetConnection;
      return o && !o.getSourceBlock().isInsertionMarker() ? i + 1 : null;
    },
    onPendingConnection(t) {
      const e = this.findInputIndexForConnection(t);
      null != e && (this.appendValueInput(`ADD${o.utils.idGenerator.genUid()}`), this.moveNumberedInputBefore(this.inputList.length - 1, e));
    },
    finalizeConnections() {
      const t = this.removeUnnecessaryEmptyConns(this.inputList.map(t => {
        var e;
        return null === (e = t.connection) || void 0 === e ? void 0 : e.targetConnection;
      }));
      this.tearDownBlock(), this.addItemInputs(t), this.itemCount = t.length;
    },
    tearDownBlock() {
      for (let t = this.inputList.length - 1; t >= 0; t--) this.removeInput(this.inputList[t].name);
    },
    removeUnnecessaryEmptyConns(t) {
      const e = [...t];
      for (let t = e.length - 1; t >= 0; t--) !e[t] && e.length > this.minInputs && e.splice(t, 1);
      return e;
    },
    addItemInputs(t) {
      var e, n;
      const i = this.addFirstInput(),
        s = t[0];
      s && (null === (e = i.connection) || void 0 === e || e.connect(s));
      for (let e = 1; e < t.length; e++) {
        const i = this.appendValueInput(`ADD${e}`),
          s = t[e];
        s && (null === (n = i.connection) || void 0 === n || n.connect(s));
      }
    },
    addFirstInput() {
      return this.appendValueInput("ADD0").appendField(o.Msg.TEXT_JOIN_TITLE_CREATEWITH);
    },
    isCorrectlyFormatted() {
      for (let t = 0; t < this.inputList.length; t++) if (this.inputList[t].name !== `ADD${t}`) return !1;
      return !0;
    }
  };
  o.Blocks.dynamic_text_join = r;
  const u = {
    minInputs: 2,
    itemCount: 0,
    init() {
      this.itemCount = this.minInputs, this.setHelpUrl(o.Msg.LISTS_CREATE_WITH_HELPURL), this.setStyle("list_blocks"), this.addFirstInput();
      for (let t = 1; t < this.minInputs; t++) this.appendValueInput(`ADD${t}`);
      this.setOutput(!0, "Array"), this.setTooltip(o.Msg.LISTS_CREATE_WITH_TOOLTIP);
    },
    mutationToDom() {
      this.isDeadOrDying() || (o.Events.disable(), this.finalizeConnections(), this instanceof o.BlockSvg && this.initSvg(), o.Events.enable());
      const t = o.utils.xml.createElement("mutation");
      return t.setAttribute("items", `${this.itemCount}`), t;
    },
    domToMutation(t) {
      t.getAttribute("inputs") ? this.deserializeInputs(t) : this.deserializeCounts(t);
    },
    deserializeInputs(t) {
      const e = t.getAttribute("inputs");
      if (e) {
        const t = e.split(",");
        this.inputList = [], t.forEach(t => this.appendValueInput(t)), this.inputList[0].appendField(o.Msg.LISTS_CREATE_WITH_INPUT_WITH);
      }
    },
    deserializeCounts(t) {
      var e;
      this.itemCount = Math.max(parseInt(null !== (e = t.getAttribute("items")) && void 0 !== e ? e : "0", 10), this.minInputs);
      for (let t = this.minInputs; t < this.itemCount; t++) this.appendValueInput("ADD" + t);
    },
    saveExtraState: function () {
      return this.isDeadOrDying() || this.isCorrectlyFormatted() || (o.Events.disable(), this.finalizeConnections(), this instanceof o.BlockSvg && this.initSvg(), o.Events.enable()), {
        itemCount: this.itemCount
      };
    },
    loadExtraState: function (t) {
      var e;
      if ("string" != typeof t) {
        this.itemCount = null !== (e = t.itemCount) && void 0 !== e ? e : 0;
        for (let t = this.minInputs; t < this.itemCount; t++) this.appendValueInput("ADD" + t);
      } else this.domToMutation(o.utils.xml.textToDom(t));
    },
    findInputIndexForConnection(t) {
      var e, n;
      if (!t.targetConnection || (null === (e = t.targetBlock()) || void 0 === e ? void 0 : e.isInsertionMarker())) return null;
      let i = -1;
      for (let e = 0; e < this.inputList.length; e++) this.inputList[e].connection == t && (i = e);
      if (i == this.inputList.length - 1) return this.inputList.length + 1;
      const s = this.inputList[i + 1],
        o = null === (n = null == s ? void 0 : s.connection) || void 0 === n ? void 0 : n.targetConnection;
      return o && !o.getSourceBlock().isInsertionMarker() ? i + 1 : null;
    },
    onPendingConnection(t) {
      const e = this.findInputIndexForConnection(t);
      null != e && (this.appendValueInput(`ADD${o.utils.idGenerator.genUid()}`), this.moveNumberedInputBefore(this.inputList.length - 1, e));
    },
    finalizeConnections() {
      const t = this.removeUnnecessaryEmptyConns(this.inputList.map(t => {
        var e;
        return null === (e = t.connection) || void 0 === e ? void 0 : e.targetConnection;
      }));
      this.tearDownBlock(), this.addItemInputs(t), this.itemCount = t.length;
    },
    tearDownBlock() {
      for (let t = this.inputList.length - 1; t >= 0; t--) this.removeInput(this.inputList[t].name);
    },
    removeUnnecessaryEmptyConns(t) {
      const e = [...t];
      for (let t = e.length - 1; t >= 0; t--) !e[t] && e.length > this.minInputs && e.splice(t, 1);
      return e;
    },
    addItemInputs(t) {
      var e, n;
      const i = this.addFirstInput(),
        s = t[0];
      s && (null === (e = i.connection) || void 0 === e || e.connect(s));
      for (let e = 1; e < t.length; e++) {
        const i = this.appendValueInput(`ADD${e}`),
          s = t[e];
        s && (null === (n = i.connection) || void 0 === n || n.connect(s));
      }
    },
    addFirstInput() {
      return this.appendValueInput("ADD0").appendField(o.Msg.LISTS_CREATE_WITH_INPUT_WITH);
    },
    isCorrectlyFormatted() {
      for (let t = 0; t < this.inputList.length; t++) if (this.inputList[t].name !== `ADD${t}`) return !1;
      return !0;
    }
  };
  function a(t) {
    return void 0 !== t.onPendingConnection && void 0 !== t.finalizeConnections;
  }
  function p(t) {
    return class {
      constructor(e) {
        this.pendingBlocks = new Set();
        const n = null != t ? t : o.InsertionMarkerPreviewer;
        this.basePreviewer = new n(e);
      }
      previewReplacement(t, e, n) {
        this.previewDynamism(e), this.basePreviewer.previewReplacement(t, e, n);
      }
      previewConnection(t, e) {
        this.previewDynamism(e), this.basePreviewer.previewConnection(t, e);
      }
      hidePreview() {
        this.basePreviewer.hidePreview();
      }
      dispose() {
        for (const t of this.pendingBlocks) {
          if (t.isDeadOrDying()) return;
          t.finalizeConnections();
        }
        this.pendingBlocks.clear(), this.basePreviewer.dispose();
      }
      previewDynamism(t) {
        const e = t.getSourceBlock();
        a(e) && (e.onPendingConnection(t), this.pendingBlocks.add(e));
      }
    };
  }
  o.Blocks.dynamic_list_create = u;
  const h = function () {
    o.Blocks.lists_create_with = o.Blocks.dynamic_list_create, o.Blocks.text_join = o.Blocks.dynamic_text_join, o.Blocks.controls_if = o.Blocks.dynamic_if;
  };
  function c(t) {
    var e;
    if (t.type === o.Events.BLOCK_DELETE) {
      const n = o.Workspace.getById(null !== (e = t.workspaceId) && void 0 !== e ? e : "");
      if (!n) return;
      for (const t of n.getAllBlocks()) a(t) && t.finalizeConnections();
    }
  }
  return s;
})());
