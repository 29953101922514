import {TranslateService} from '@ngx-translate/core';
import * as Blockly from 'blockly';
import {Block, MenuOption} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';

export interface BlockOutputArg {
  getFieldValue(name: string): any;
}

export interface BlockOutput {
  result: any;
  order: any;
}

export interface BlockCategoryContent {
  kind: string;
  type: string;
}
export interface BlockCategoryOutput {kind: string; name: any; colour: string; contents: BlockCategoryContent[]}

export class BlockCategory {
  constructor(
    readonly name: string,
    readonly color: string,
    readonly contents: BlockDefinition[]
  ) {
  }

  get active(): boolean {
    return this.contents.some(d => d.active);
  }

  define(translateService: TranslateService): BlockCategoryOutput | null {
    if (!this.active) {return null;}
    this.contents
      .filter(d => d.active)
      .forEach(d => {
        d.define(translateService, this.color);
      });
    const name: string = this.name.startsWith('#') ? translateService.instant(this.name.slice(1)) : this.name;
    return {
      kind: 'category',
      name,
      colour: this.color,
      contents: this.contents.filter(d => d.active).map(d => ({kind: 'block', type: d.name})),
    };
  }
}

export abstract class BlockDefinition {
  protected constructor(
    readonly blockType: string,
    readonly ident: string,
    readonly active: boolean,
  ) {
    this.name = `${this.blockType}-${ident}`;
  }
;

  static literalComparators = {
    '=': 'eq',
    '!=': 'ne',
    '>': 'gt',
    '>=': 'gte',
    '<': 'lt',
    '<=': 'lte'
  } as any;

  static comparators: MenuOption[] = [
    ['=', '='],
    ['!=', '!='],
    ['>', '>'],
    ['>=', '>='],
    ['<', '<'],
    ['<=', '<='],
  ];
  readonly name: string;
  static numValidator(newValue: any) {
    return Math.trunc(newValue);
  }
  abstract init(translateService: TranslateService, block: Block, color: string);
  abstract output(block: BlockOutputArg): BlockOutput;
  protected extras(translateService: TranslateService, color: string): Record<string, any> {
    return {};
  }


  define(translateService: TranslateService, color: string) {
    const self = this;
    Blockly.Blocks[this.name] = {
      ...this.extras(translateService, color),
      init() {
        self.init(translateService, this, color);
      },
    };
    javascriptGenerator.forBlock[this.name] = (block: BlockOutputArg) => {
      const res = self.output(block);
      return [JSON.stringify(res.result), res.order];
    };
  }

  fieldNumber(defValue: number, validator: any) {
    return new Blockly.FieldNumber(defValue, validator);
  }
  fieldText(defValue: string, validator: any) {
    return new Blockly.FieldTextInput(defValue, validator);
  }

  /**
   * Adds a mutationToDom function to generate a mutation xml element
   *
   * see https://github.com/confinale/aspark/pull/8354
   *
   * @param exchangeRate
   * @param cbAmount
   * @param cbSuffix
   * @protected
   * <block type="filter_composite-operators" id="p;Y2a;cI80J*}YrOZGx,">
   *   <mutation inputs="ADD0,ADD1" next="2" />
   *   <field name="operator">and</field>
   *   <value name="ADD0">
   *      <block type="filter_field-number-portfolio" id="k$*V$/Y.!$BKLO595x5a">
   *         <mutation baseAmount="50"/>
   *         <field name="field">portfolio.Value</field>
   *         <field name="operator">=</field>
   *         <field name="field_value">100</field>
   *      </block>
   *   </value>
   *   <value name="ADD1">
   *      <block type="filter_field-number-position" id="MaPeZ!$d$2JZawOZj%Cf">
   *         <mutation baseAmount="100"/>
   *         <field name="field">position.Value</field>
   *         <field name="operator">=</field>
   *         <field name="field_value">200</field>
   *      </block>
   *   </value>
   * </block>
   */
  protected extrasCurrency(
    exchangeRate: number,
    cbAmount: (block: Block) => number,
    cbSuffix: (block: Block) => string): Record<string, any> {
    return {
      mutationToDom(): Element | null {
        const block = this;
        const suffix = cbSuffix(block);
        const isCurrency = !!suffix && suffix != '%';
        const amount = cbAmount(block);
        if (!isCurrency || amount == null) return null;
        let newAmount = amount * exchangeRate;
        // create mutation element
        const container = Blockly.utils.xml.createElement('mutation');
        container.setAttribute("baseAmount", newAmount.toString());
        return container;
      },
    };
  }
}
