import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {filter, finalize, first, Observable, Subscription} from 'rxjs';
import {
  Campaign,
  CampaignAction,
  CampaignActionPaginated,
  CampaignActionSelection,
  CampaignActionService,
  CampaignService, CollectionEntryPaginated, CollectionEntrySelection,
  CollectionService,
  Content, DynamicUser,
  GridFilterOptionsParams,
  ListParams,
  ListParamsWithSelection, UserInfo,
  UserInteraction,
  UserService,
} from 'src/app/api/core';
import {DataService} from 'src/app/services/data.service';
import {ECodeTables, EModalType} from 'src/app/util/enum';
import {DialogHeight, DialogWidth, ModalService} from "../../../../services/modal.service";
import {
  ColDef,
  ColumnState,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  RowSelectedEvent,
  SelectionChangedEvent,
  SortChangedEvent
} from "ag-grid-community";
import {genHeaderCheckboxColumn, GridSelectionUtils} from "../../../../util/grid/grid-selection.util";
import {
  GridDataProvider,
  GridSelectedItemsProvider,
  GridSelectedItemsProviderByCollection
} from "../../../../shared/grid/data-source";
import {
  genCodeTableColumn,
  genEnumColumn,
  genTextColumnWithAutoCompleteFilter, genUserEnumColumn, usernameValueLabel
} from "../../../../util/grid/grid-renderer.util";
import {TranslateService} from "@ngx-translate/core";
import {CodeTableService} from "../../../../services/code-table.service";
import {EProtectedActions} from "../../../../util/protected-actions";
import {PermissionService} from "../../../../services/permission.service";
import {ActionType, CampaignActionListUtils, ContentAction} from "../../campaign-overview/campaign-actions-list-utils";
import {NotificationService} from "../../../../services/notification.service";
import {CustomPreviewService} from "../../../../services/custom-preview.service";
import {genSuitabilityColumn} from "../../../../shared/grid/cell-renderers/suitability.renderer";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {ModalData} from "../../../../models/modal.model";
import {
  CampaignActionProcessingComponent
} from "../../campaign-overview/campaign-action-processing/campaign-action-processing.component";
import {ContentFormComponent} from "../../../../shared/content-form/content-form.component";
import {ContentData} from "../../../../models/content.model";
import {
  ButtonAction,
  DropdownSelectionBarComponent
} from "../../../../shared/grid/custom-header/dropdown-selection-bar/dropdown-selection-bar.component";
import {DatasourceFilter} from "../../../../shared/grid/datasource-filter";
import {GlobalService} from "../../../../services/global.service";
import {
  UserCollectionPickerComponent
} from "../../../../my-settings/dialogs/user-collection-picker/user-collection-picker.component";
import {map} from "rxjs/operators";
import {CampaignActionEditComponent} from "../campaign-action-edit/campaign-action-edit.component";
import {
  CampaignCompactTargetedClientsComponent
} from "../campaign-compact-targeted-clients/campaign-compact-targeted-clients.component";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {IsCampaignEditablePipe} from "../../../../shared/shared.pipe";
import {ICONS_CONFIG} from "../../../../../assets/config/icons_config";
import {genIconLabelColumn} from "../../../../shared/grid/cell-renderers/icon-label.renderer";
import {GridComponent} from "../../../../shared/grid/grid.component";
import {HtmlService} from "../../../../services/html.service";
import {
  CampaignCollectionAvailableEntriesComponent
} from "../../campaign-collection-available-entries/campaign-collection-available-entries.component";

/**
 * Component for fast-track campaign overview.
 */
@Component({
  selector: 'app-campaign-compact-send',
  templateUrl: './campaign-compact-send.component.html',
})
export class CampaignCompactSendComponent implements OnInit, OnDestroy {
  //
  @Output() viewSwitched = new EventEmitter<void>();
  @Output() onBack = new EventEmitter<void>();
  // selection-components
  @ViewChild('selectionDropdown', {static: true})
  selectionDropdown: DropdownSelectionBarComponent;
  @ViewChild("campaign_compact_available_list")
  availableGrid: GridComponent;
  automaticallyCreatedCollections: ButtonAction[] = [
    {
      text: 'preselectionRules',
      click: () => this.selectAllPagesPreselection(),
      show: () => true,
      privateCollection: false,
    },
  ];
  createNewCollectionAction: ButtonAction = {
    text: 'collectionsAdd',
    className: 'create-collection-btn',
    click: () => this.createNewCollection(),
    show: () => true,
    privateCollection: false,
  };
  selectionHiddenActions: ButtonAction[] = [...this.automaticallyCreatedCollections, this.createNewCollectionAction];
  collectionIds: Set<number> = new Set<number>();
  //
  @Input()
  campaign: Campaign;
  private subscriptions: Subscription[] = [];

  availableData: GridDataProvider;
  private dataCount = 0;
  protected dataIsSelected = false;
  protected availableGridApi: GridApi;
  protected selectedGridApi: GridApi;
  selectedSort: string;
  availableColumnDefs: ColDef[] = [];
  selectedColumnDefs: ColDef[] = [];
  protected availableGridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressDragLeaveHidesColumns: true,
    rowHeight: 36,
    suppressCellFocus: true,
    readOnlyEdit: true,
    enableCellTextSelection: true,
    ensureDomOrder: true,
    getRowId: params => params.data.id,
    detailRowAutoHeight: true,
    tooltipShowDelay: 100,
    onGridReady: (event: GridReadyEvent) => {
      this.availableGridApi = event.api;
      this.fetchAssignees({
        success: () => {
          this.presetAssigneeFilter(event.api);
        },
      });
    },
    onSelectionChanged: (event: SelectionChangedEvent) => {
      if (event.source === 'uiSelectAll') {
        this.toggleSelection();
      }
    },
    onRowSelected: (event: RowSelectedEvent) => {
      if (event.source === 'uiSelectAll') {
        return;
      }
      this.gridSelectionUtils.setSelection(
        event?.data?.id,
        event?.data,
        event?.node?.isSelected()
      );
    },
    onSortChanged: (event: SortChangedEvent) => {
      this.selectedSort = event.api.getColumn('id')?.getSort();
    },
    getContextMenuItems: (params: GetContextMenuItemsParams) => [],
  };

  protected selectedData: CampaignAction[] = [];
  protected selectedGridOptions: GridOptions = {
    rowHeight: 36,
    suppressCellFocus: true,
    suppressDragLeaveHidesColumns: true,
    readOnlyEdit: true,
    enableCellTextSelection: true,
    ensureDomOrder: true,
    getRowId: params => params.data.id,
    detailRowAutoHeight: true,
    tooltipShowDelay: 100,
    onGridReady: (event: GridReadyEvent) => {
      this.selectedGridApi = event.api;
    },
    getContextMenuItems: (params: GetContextMenuItemsParams) => [],
  };

  selectAllProvider: GridSelectedItemsProvider;
  selectAllPreselectionProvider: GridSelectedItemsProvider;
  selectAllByCollectionPreselectionProvider: GridSelectedItemsProviderByCollection;

  protected gridSelectionUtils = new GridSelectionUtils(
    () => true,
    (data) =>
      data.extractedInfo
        ? data
        : {
          id: data.id,
          status: data.status,
          advisor: data.portfolio?.advisor,
          hasCidPermission: data.hasCidPermission,
          campaignPortfolioId: data.campaignPortfolioId,
          action: data,
          portfolio: data.portfolio,
          extractedInfo: true,
        },
    'id', // indexField
    true // useAllPortfolioData, this is true because we need all portfolio data for the selection
  );

  private listUtils: CampaignActionListUtils;
  assignees: UserInfo[];
  initialAssignees: UserInfo[] = [];
  selectedAssignees: string[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly codeTableService: CodeTableService,
    private readonly permissionService: PermissionService,
    private readonly dataService: DataService,
    private readonly modalService: ModalService,
    private readonly campaignService: CampaignService,
    private readonly campaignActionService: CampaignActionService,
    protected readonly notificationService: NotificationService,
    protected readonly customPreviewService: CustomPreviewService,
    protected readonly globalService: GlobalService,
    protected readonly collectionService: CollectionService,
    protected readonly userService: UserService,
    protected readonly zone: NgZone,
    protected readonly htmlService: HtmlService,
    protected readonly isCampaignEditablePipe: IsCampaignEditablePipe,
    protected readonly campaignActionsService: CampaignActionService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.gridSelectionUtils.getSelectionChangeObservable().subscribe(() => {
        this.dataIsSelected = this.gridSelectionUtils.getSelectedValues().length > 0;
      }),
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.initColumnDefs();
          this.availableData = this.fetchData.bind(this);
          this.selectAllProvider = this.fetchAll.bind(this);
          this.selectAllPreselectionProvider = this.fetchAllPreselection.bind(this)
          this.selectAllByCollectionPreselectionProvider = this.fetchAllByCollection.bind(this);
        }),
      this.permissionService.user$.subscribe(userRoleData => {
        this.updateSelectionHiddenActions();
        this.htmlService.waitFor(
          () => this.availableGrid != null,
          () => {
            this.clearAvailableSelection();
            this.availableGridApi.refreshServerSide();
            const dynamicUsers = userRoleData.dynamicUsers
              .filter(u => u.enabled).filter(Boolean);
            this.updateAssigneesBasedOnDynamicUsers(dynamicUsers);
            this.presetAssigneeFilter(this.availableGridApi, dynamicUsers);
          }
        );
      }),
    );
    localStorage.removeItem('campaignOverviewTab');
    this.listUtils = new CampaignActionListUtils(
      this.zone,
      this.campaign,
      this.notificationService,
      this.modalService,
      this.translateService,
      this.permissionService,
      this.isCampaignEditablePipe,
      this.customPreviewService,
      this.campaignActionService,
      null,
      this.userService,
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Fetch only PENDING actions.
   * @param gridParams
   * @private
   */
  private fetchData(gridParams: ListParams): Observable<CampaignActionPaginated> {
    const params = {
      ...gridParams,
      selectedItems: this.selectedSort ? this.gridSelectionUtils.getSelectedValues().map((item) => item.id) : [],
      selectedSort: this.selectedSort,
      excludedItems: this.selectedData.map((item) => item.id),
    } as ListParamsWithSelection;
    this.toggleOverlay(true);
    return this.campaignActionService.getCampaignActionsReadyForSending(
      this.campaign.id,
      params
    ).pipe(
      map((data) => {
        this.dataCount = data.count;
        return data;
      }),
      finalize(() => {
        this.toggleOverlay(false);
      }));
  }

  private fetchAll(): Observable<CampaignActionSelection[]> {
    const filter = new DatasourceFilter(this.availableGridApi, this.globalService);
    this.toggleOverlay(true);
    const filterStr = this.preparedFilterString(filter.toString());
    return this.campaignActionService.getCampaignActionsSelection(
      this.campaign.id, false, filterStr
    ).pipe(
      map((data) => {
        this.dataCount = data.length;
        return data;
      }),
      finalize(() => {
        this.toggleOverlay(false);
      }));
  }

  private fetchAllPreselection(): Observable<CampaignActionSelection[]> {
    const filter = new DatasourceFilter(this.availableGridApi, this.globalService);
    this.toggleOverlay(true);
    const filterStr = this.preparedFilterString(filter.toString());
    return this.campaignActionService.getCampaignActionsSelection(
      this.campaign.id, true, filterStr
    ).pipe(
      map((data) => {
        this.dataCount = data.length;
        return data;
      }),
      finalize(() => {
        this.toggleOverlay(false);
      }));
  }

  private fetchAllByCollection(collectionId: number): Observable<CampaignActionSelection[]> {
    const filter = new DatasourceFilter(this.availableGridApi, this.globalService);
    this.toggleOverlay(true);
    const filterStr = this.preparedFilterString(filter.toString());
    return this.campaignActionService.getCampaignActionsSelectionByUserCollection(
      this.campaign.id, collectionId, filterStr
    ).pipe(
      map((data) => {
        this.dataCount = data.length;
        return data;
      }),
      finalize(() => {
        this.toggleOverlay(false);
      }));
  }

  toggleOverlay(loading: boolean) {
    if (loading) {
      this.availableGridApi.showLoadingOverlay();
      this.selectedGridApi.showLoadingOverlay();
    } else {
      this.availableGridApi.hideOverlay();
      this.selectedGridApi.hideOverlay();
    }
  }

  private initColumnDefs() {
    if (this.selectedColumnDefs.length > 0) {
      return;
    }
    const isCidFilterableSortable = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    const baseColumns = [
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'client.fullName',
          headerName: this.translateService.instant('client'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.campaignService.getGridFilterOptions(data),
            autoCompleteField: 'fullName',
            autoCompleteContextId: this.campaign.id,
          },
        }),
        headerTooltip: this.translateService.instant('client'),
        floatingFilter: isCidFilterableSortable,
        sortable: isCidFilterableSortable,
        flex: 1,
      },
      {
        ...genIconLabelColumn({
          field: 'portfolio.number',
          headerName: this.translateService.instant('portfolioNumber'),
          labelCallback: (data: CampaignAction) =>
            this.listUtils.openPortfolioDetailsModal(data.portfolio),
          label: (data: CampaignAction) => data.portfolio.number,
          tooltip: 'showDetails',
          icon: (data: CampaignAction) => (data.portfolioContainsBuyProducts || false).toString() == 'true' ? 'warning' : undefined,
        }),
        filterParams: {
          customPath: 'campaignPortfolio.portfolio.number',
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.campaignService.getGridFilterOptions(data),
            autoCompleteField: 'number',
            autoCompleteContextId: this.campaign.id,
          },
        },
        headerTooltip: this.translateService.instant('portfolioNumber'),
        floatingFilter: isCidFilterableSortable,
        sortable: isCidFilterableSortable,
      },
      {
        ...genEnumColumn({
          field: 'portfolioContainsBuyProducts',
          headerName: this.translateService.instant('portfolioContainsBuyProducts'),
          values: ['true', 'false'],
          filterParamsInfo: {
            onlyOne: true,
            customPath: 'campaignPortfolio.portfolioContainsBuyProducts'
          }
        }),
        hide: true,
      },
      {
        ...genCodeTableColumn({
          field: 'language',
          headerName: this.translateService.instant('languageActual'),
          observable: this.codeTableService.getCodeTable(ECodeTables.language),
        }),
        headerTooltip: this.translateService.instant('languageActual'),
      },
      {
        ...genCodeTableColumn({
          field: 'channel.type',
          headerName: this.translateService.instant('channel'),
          observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
          filterHubs: () => [this.campaign.hub.ident],
        }),
        headerTooltip: this.translateService.instant('channel'),
      },
      {
        ...genSuitabilityColumn({
          translateService: this.translateService,
          field: 'combinedSuitabilityState',
          stateInfo: (data: CampaignAction) => ({
            state: data.combinedSuitabilityState,
            campaignActionId: data.id,
          }),
          headerName: this.translateService.instant('suitability'),
          callback: (data: any) => this.listUtils.openClientSuitability(data),
        }),
        headerTooltip: this.translateService.instant('suitability'),
        suppressHeaderMenuButton: true,
        width: 35,
        minWidth: 35,
      },
      {
        ...genIconButtonColumn({
          icon: (data) => data.hasContentOverride ? 'view' : 'preview',
          callback: (data: CampaignAction) => this.openPreview(data),
        }),
        tooltipValueGetter: (data) => this.translateService.instant(data.data.content ? 'viewCustomContent' : 'preview'),
        cellClass: (data) => data.data.content ? 'cell-custom-content': undefined,
        sortable: false,
        resizable: false,
        width: 36,
        maxWidth: 36,
      },
    ];
    this.availableColumnDefs = [
      {
        ...genHeaderCheckboxColumn(
          this.gridSelectionUtils,
          true, 0, 'id', true,
          this.translateService.instant('toggleSelection'),
        ),
        sortable: false,
        headerClass: 'header-checkbox',
        headerCheckboxSelection: true,
        lockVisible: true,
        unSortIcon: undefined,
        width: undefined,
        minWidth: undefined,
        pinned: 'left',
        lockPinned: true,
      },
      ...baseColumns,
      {
        ...genUserEnumColumn(
          'assignee.username',
          this.translateService.instant('assignee'),
          this.fetchAssignees.bind(this),
          () => this.assignees
        ),
        valueFormatter: (r) =>
          usernameValueLabel(r.data.assignee),
        hide: true,
      },
      {
        ...genIconButtonColumn({
          icon: 'more_vert',
          callback: (data: CampaignAction) => {
            const node = this.availableGridApi.getRowNode(data.id.toString());
            const column = this.availableGridApi.getColumn('icon-button-more_vert');
            this.availableGridApi.showContextMenu({rowNode: node as any, column, value: data});
          },
        }),
        tooltipValueGetter: (_) => this.translateService.instant('customizeAction'),
        contextMenuItems: this.getEditContextMenuItems.bind(this),
        sortable: false,
        resizable: false,
        maxWidth: 32,
        pinned: 'right',
        lockPinned: true,
      },
    ];
    this.selectedColumnDefs = [
      {
        ...genIconButtonColumn({
          callback: (action: CampaignAction) =>
            this.deleteSelected(action.id),
          icon: 'delete',
        }),
        sortable: false,
        resizable: false,
        maxWidth: 32,
        pinned: 'left',
        lockPinned: true,
      } as ColDef,
      ...baseColumns,
      {
        ...genIconButtonColumn({
          icon: 'more_vert',
          callback: (data: CampaignAction) => {
            const node = this.selectedGridApi.getRowNode(data.id.toString());
            const column = this.selectedGridApi.getColumn('icon-button-more_vert');
            this.selectedGridApi.showContextMenu({rowNode: node as any, column, value: data});
          },
        }),
        tooltipValueGetter: (_) => this.translateService.instant('customizeAction'),
        contextMenuItems: this.getEditContextMenuItems.bind(this),
        sortable: false,
        resizable: false,
        maxWidth: 32,
        pinned: 'right',
        lockPinned: true,
      } as ColDef,
    ]
      .map(d => {
        return {...d, checkboxSelection: false};
      });
  }

  /**
   * Add non-selected items to the selected list.
   */
  addSelected() {
    const selected = this.gridSelectionUtils.getSelectedValues().map(d => d.action);
    const toAdd = selected.filter(d => !this.selectedData.find(sd => sd.id === d.id));
    this.selectedData = this.selectedData.concat(...toAdd).map(r => ({
      ...r,
      portfolioContainsBuyProducts: (r.portfolioContainsBuyProducts || false).toString()
    } as unknown as CampaignAction));
    // clear selection and remove selected entries
    this.clearAvailableSelection();
    this.availableGridApi.refreshServerSide();
  }

  executeSelectedActions() {
    const modalType = EModalType.executeActionsDialog;
    const selected = this.selectedData.map(d => ({action: d, portfolio: d.portfolio}));
    const modalData: ModalData = {
      title: null,
      type: modalType,
      data: {modalType, campaign: this.campaign, selectedRows: selected, isHierarchyList: false},
      component: CampaignActionProcessingComponent,
      submitBtn: {label: ''},
      cancelBtn: {label: this.translateService.instant('cancel')},
    };
    const dialogRef = this.modalService.openDefaultDialog(modalData, undefined, undefined, true);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearSelection();
        this.refreshGrids();
      }
    });
  }

  clearSelection() {
    this.clearAvailableSelection();
    this.selectedData = [];
  }

  refreshGrids() {
    this.availableGridApi.refreshServerSide();
  }

  private openPreview(data: CampaignAction) {
    const contentAction: ContentAction = {
      id: data.id,
      type: ActionType.CampaignAction,
      campaignId: this.campaign.id,
      language: data.language,
      channel: data.channel,
      content: data.content,
    }
    this.customPreviewService.previewCustomContent(contentAction, false, true)
  }

  private getEditContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const hasContent = params.value.hasContentOverride;
    const createIcon = (icon: string) => [
      `<app-config-icon icon-identifier="${icon}">`,
      `  <span class="mat-mdc-tooltip-trigger material-symbols-outlined">${ICONS_CONFIG[icon].name}</span>`,
      '</app-config-icon>'
    ].join('\n');
    return hasContent ? [
      {
        name: this.translateService.instant('editCustomContent'),
        icon: createIcon("edit_m"),
        action: () => this.showCustomContent(params.value),
      },
      {
        name: this.translateService.instant('deleteCustomContent'),
        icon: createIcon("delete"),
        action: () => this.deleteCustomContent(params.value),
      },
      {
        name: this.translateService.instant('viewCampaignChannel'),
        icon: createIcon("view"),
        action: () => this.showChannel(params.value),
      }
    ] : [
      {
        name: this.translateService.instant('addPersonalizedSalutation'),
        icon: createIcon("add"),
        action: () => this.showCustomContent(params.value),
      },
      {
        name: this.translateService.instant('editLanguageAndChannel'),
        icon: createIcon("edit_m"),
        action: () => this.showEditModal(params.value),
      },
    ]; //remove undefined
  }

  private showChannel(data: any) {
    this.listUtils.handleChannelCellClick(
      data, ActionType.CampaignAction, () => {}, true
    );
  }

  private showEditModal(action: CampaignAction) {
    const modalType = EModalType.updateActionDetails;
    const modalData: ModalData = {
      title: EModalType.updateActionDetails,
      type: modalType,
      data: {modalType, action},
      component: CampaignActionEditComponent,
      submitBtn: {label: this.translateService.instant('update')},
      cancelBtn: {label: this.translateService.instant('cancel')},
    };
    const dialogRef = this.modalService.openDefaultDialog(modalData, undefined, undefined, true, DialogWidth.HALF, DialogHeight.AUTO);
    dialogRef.afterClosed().subscribe((afterAction: CampaignAction) => {
      if (afterAction) {
        this.refreshGrids();
        // update selected data with the incoming action, also update the selection if present
        this.gridSelectionUtils.updateSelection(afterAction.id, afterAction)
        this.selectedData = this.selectedData.map(d => d.id === afterAction.id ? {...d, ...afterAction} : d);
        if (action.channel.type.id !== afterAction.channel.type.id) {
          this.userService.recordUserInteraction(UserInteraction.CHANGEDCAMPAIGNACTIONCHANNEL).pipe(first()).subscribe();
        }
        if (action.language.id !== afterAction.language.id) {
          const toLang = afterAction.language.ident;
          this.userService.recordUserInteraction(
            toLang == 'en' ? UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETOEN
              : toLang == 'de' ? UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETODE
                : UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETOOTHER
          ).pipe(first()).subscribe();
        }
      }
    });
  }

  private showCustomContent(action: CampaignAction) {
    const contentData = (content: Content) => {
      return new ContentData(
        ActionType.CampaignAction,
        content.language,
        action.id,
        content,
        true,
        this.campaign.id,
        action.channel,
        content.contentDefinitionId,
        this.campaign.contentImageHandling,
      )
    };
    const showModal = (modalData: ModalData) => {
      const dialogRef = this.modalService.openDefaultDialog(modalData, 'custom-content-padding');
      dialogRef.afterClosed().subscribe((result: CampaignAction) => {
        if (result) {
          this.notificationService.handleSuccess(
            this.translateService.instant('contentUpdateSuccess')
          );
          // update selectedData with the incoming action marking it as having a content override
          this.selectedData = this.selectedData.map(d => d.id === result.id ? {...result} : d);
          this.refreshGrids();
          if (modalData.type === EModalType.createCustomContent) {
            this.userService.recordUserInteraction(UserInteraction.ADDEDCAMPAIGNACTIONCONTENT).pipe(first()).subscribe();
          }
        }
      });
    }
    if (action.content) {
      const modalData: ModalData = {
        type: EModalType.updateCustomContent,
        title: EModalType.updateCustomContent,
        data: contentData(action.content),
        component: ContentFormComponent,
      };
      showModal(modalData);
    } else {
      this.campaignActionService.createEphemeralCampaignActionContent(action.id).subscribe((content) => {
        const modalData: ModalData = {
          type: EModalType.createCustomContent,
          title: EModalType.createCustomContent,
          data: contentData(content),
          component: ContentFormComponent,
        };
        showModal(modalData);
      });
    }
  }

  clearAvailableSelection() {
    this.gridSelectionUtils.clearSelection();
    this.availableGridApi.deselectAll();
  }

  selectAllPagesPreselection() {
    const gridApi = this.availableGridApi
    const filter = new DatasourceFilter(gridApi, this.globalService);
    this.selectAllPreselectionProvider(filter.toString()).subscribe((items) => {
      this.setSelected(items);
      this.sortBySelected();
      gridApi.refreshServerSide({purge: true}); // force server side refresh, to apply sorting
    });
  }

  private sortBySelected() {
    const gridApi = this.availableGridApi;
    const colStates: ColumnState[] = gridApi.getColumnState()
      .map(colState => ({
        ...colState,
        sort: colState.colId === 'id' ? 'desc' : null
      }));
    gridApi.applyColumnState({state: colStates});
  }

  private createNewCollection() {
    const modalData: ModalData = {
      type: EModalType.pickClients,
      title: 'collectionsAdd',
      data: {
        name: '',
        isPublic: false,
        selected: [],
      },
      component: UserCollectionPickerComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(modalData, 'pick-client-dialog');
    dialogRef.afterClosed().subscribe((result: number[]) => {
      if (result) {
        this.updateSelectionHiddenActions().then(() => {
          this.selectionDropdown.selectedAction = this.selectionHiddenActions.find((action) =>
            action.collectionId && action.collectionId === result[0]
          );
        });
      }
    });
  }

  private updateSelectionHiddenActions(): Promise<void> {
    return new Promise((resolve) => {
      this.collectionService.getUserAndDeputyCollections()
        .subscribe((collections) => {
          const newCollections = collections.filter((c) =>
            !this.collectionIds.has(c.id)
          );
          this.collectionIds = new Set<number>(collections.map((c) => c.id));
          const newActions = newCollections.map(
            (c) =>
              ({
                text: c.isOwner ? c.name : `${c.name} (${c.user.fullname})`,
                click: () => this.previewAvailableCollectionEntries(c.id),
                disabled: () => false,
                show: () => true,
                collectionId: c.id,
                privateCollection: c.isOwner,
              } as ButtonAction)
          );
          this.selectionHiddenActions.push(...newActions);
          this.selectionHiddenActions = this.selectionHiddenActions.filter((action) =>
            this.collectionIds.has(action.collectionId) || action.collectionId === undefined
          );
          // asynchronous operations need selectionHiddenActions to be updated before resolving
          resolve();
          // clear selection if previously selected collection is not in list anymore
          if (
            this.selectionDropdown &&
            this.selectionDropdown._selectedAction?.collectionId &&
            !this.collectionIds.has(this.selectionDropdown._selectedAction.collectionId)
          ) {
            this.clearAvailableAndCollectionSelection();
          }
        });
    });
  }

  private previewAvailableCollectionEntries(collectionId: number) {
    this.toggleOverlay(true);
    const campaignId = this.campaign.id;
    const listParams = { firstResult: 0, pageSize: 100 } as ListParams;
    this.campaignService.getAvailableCollectionEntries(campaignId, collectionId, listParams)
      .subscribe( {
        next: (result: CollectionEntryPaginated) => {
          if (result.count === 0) {
            this.selectCollection(collectionId);
          } else {
            const modalData: ModalData = {
              type: EModalType.addCampaignAvailableCollectionEntries,
              title: EModalType.addCampaignAvailableCollectionEntries,
              data: {
                campaignId, collectionId
              },
              submitBtn: undefined,
              cancelBtn: undefined,
              component: CampaignCollectionAvailableEntriesComponent,
            };
            const dialogRef = this.modalService.openDefaultDialog(modalData, undefined, true);
            dialogRef.afterClosed().subscribe((result: any) => {
              if (result && result.selection) {
                this.toggleOverlay(true);
                this.campaignService.addCollectionEntries(campaignId, collectionId, result.selection).subscribe({
                  next: () => this.selectCollection(collectionId),
                  complete: () => this.toggleOverlay(false),
                });
              } else if (result.collectionId) {
                this.selectCollection(collectionId);
              } else {
                this.clearSelection();
                this.toggleOverlay(false);
              }
            })
          }
        },
        complete: () => {
          this.availableGridApi.hideOverlay();
          this.selectedGridApi.hideOverlay();
        }
      });
  }

  private selectCollection(collectionId: number) {
    const gridApi = this.availableGridApi;
    this.clearAvailableSelection();
    this.selectAllByCollectionPreselectionProvider(collectionId)
      .subscribe((items) => {
        this.setSelected(items)
        this.sortBySelected();
        gridApi.setFilterModel(null);
        gridApi.refreshServerSide({purge: true}); // force server side refresh, to apply sorting
      });
  }

  private setSelected(items: any[]) {
    this.gridSelectionUtils.setMultipleSelection(items, true);
    this.gridSelectionUtils.getSelectedValues().forEach(d => {
      const node = this.availableGridApi.getRowNode(d.id.toString());
      node?.setSelected(true);
    });
  }

  clearAvailableAndCollectionSelection() {
    this.selectionDropdown?.clear();
    this.clearAvailableSelection();
  }

  private deleteSelected(actionId: number) {
    this.selectedData = this.selectedData.filter((d) => d.id !== actionId);
    this.availableGridApi.refreshServerSide();
  }

  goBack() {
    this.onBack.emit();
  }

  /**
   * Toggle selection of all items:
   * - if all items are selected, deselect all
   * - if some items are selected, select all
   * - if no items are selected, select all
   * @private
   */
  private toggleSelection() {
    const filter = new DatasourceFilter(this.availableGridApi, this.globalService);
    const selected = this.gridSelectionUtils.getSelectedValues();
    this.selectionDropdown.clear();
    if (selected.length < this.dataCount) {
      this.selectAllProvider(filter.toString()).subscribe((items) => {
        this.gridSelectionUtils.setMultipleSelection(items, true);
      });
    } else if (selected.length === this.dataCount) {
      this.clearAvailableSelection();
    }
  }

  showTargetedClients() {
    const modalData: ModalData = {
      type: EModalType.targetedClients,
      title: EModalType.targetedClients,
      submitBtn: null,
      cancelBtn: {label: this.translateService.instant('close')},
      data: {campaign: this.campaign},
      component: CampaignCompactTargetedClientsComponent,
    }
    this.modalService.openDefaultDialog(modalData, undefined, true, false, DialogWidth.DEFAULT, DialogHeight.HALF);
  }

  /**
   * Prepare filter string for the available data.
   * @param existingFilter
   * @private
   */
  private preparedFilterString(existingFilter: string) {
    const userData = this.permissionService.userRoleData;
    const usernames = Array.from(new Set([userData.username, ...userData.dynamicUsers.map(d => d.username)]));
    const predefinedFilter = 'status=eq PENDING&assignee.username=eq ' + usernames.join(',');
    if (existingFilter === undefined || existingFilter.trim() === '') {
      return predefinedFilter
    } else {
      return `${existingFilter}&${predefinedFilter}`;
    }
  }

  private deleteCustomContent(action: ContentAction): void {
    const modalData: ModalData = {
      title: this.translateService.instant('deleteCustomContent?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('deleteCustomContentMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.campaignActionService.deleteCampaignActionContent(action.id)
            .pipe(
              first(),
              finalize(() =>
                modalRef.componentInstance.resetToolbarActionButtons()
              )
            )
            .subscribe({
              next: (data) => {
                modalRef.close(true);
                // update selectedData with the incoming action marking it as having a content override
                this.selectedData = this.selectedData.map(d => d.id !== action.id ? d : data);
                this.refreshGrids();
                this.notificationService.handleSuccess(
                  this.translateService.instant('deleteCustomContentSuccess')
                );
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.zone.run(() => {
      this.modalService.openConfirmationDialog(modalData);
    });
  }

  private fetchAssignees(params: any) {
    if (this.assignees) {
      params.success(this.assignees.map((d) => d.username));
    } else {
      this.campaignActionsService
        .getCampaignActionsAssignees(this.campaign.id)
        .subscribe((data) => {
          this.assignees = data;
          this.initialAssignees = this.assignees;
          this.updateAssigneesBasedOnDynamicUsers();
          params.success(this.assignees.map((d) => d.username));
        });
    }
  }

  private updateAssigneesBasedOnDynamicUsers(curDynamicUsers: UserInfo[] | null = null) {
    if (!curDynamicUsers) curDynamicUsers = this.permissionService.userRoleData.dynamicUsers
      .filter(u => u.enabled).filter(Boolean)
      .map(u => ({id: u.id, username: u.username, fullname: u.fullname}));
    this.assignees = this.initialAssignees.concat(
      curDynamicUsers.filter(u => !this.initialAssignees.find(i => i.username === u.username)));
  }

  private presetAssigneeFilter(gridApi: GridApi, curDynamicUsers: DynamicUser[] | null = null) {
    if (!curDynamicUsers) curDynamicUsers = this.permissionService.userRoleData.dynamicUsers
      .filter(u => u.enabled).filter(Boolean)
    const currentUsernames = [
      this.permissionService.userRoleData.username,
      ...curDynamicUsers
        .filter(u => u.enabled)
        .map(u => u.username)
    ].filter(Boolean);
    this.listUtils.setAssigneeFilter(
      gridApi,
      currentUsernames,
      'assignee.username',
      this.assignees,
      this.selectedAssignees
    ).then(filter => {
      if (!filter) {
        this.assignees = [];
        this.availableGrid.refresh();
      }
    });
  }
}
